.Event {
	width: max-content;
	height: 140px;
	display: flex;
	text-align: left;
	margin-left: 30px;
	margin-bottom: 30px;
}

.Event:first-child {
	margin-left: 0px;
}

.Event .Left {
	color: #ffffff;
	width: 160px;
	height: 100%;
	padding: 10px 15px;
	background-color: #2f4454;
	display: grid;
	align-content: space-between;
	position: relative;
	box-shadow: 2.64888px 0px 17.6592px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
}

.Event .Left:after {
	content: "";
	float: left;
	position: absolute;
	top: 35%;
	right: -15px;
	width: 0;
	height: 0;
	border-color: transparent transparent transparent #2f4454;
	border-style: solid;
	border-width: 17px 0 17px 25px;
}

.Event .TopContainer {
	/* margin: 15px; */
}

.Event .DateMonth {
	font-family: 'Roboto', sans-serif;
	font-size: 27px;
	margin: 0px;
	margin-bottom: 5px;
}

.Event .DayTime {
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	text-align: left;
	font-weight: bold;
	color: rgba(242, 242, 242, 0.8);
	margin: 0px;
}

.Event .BottomContainer .Register {
	width: 84px;
	height: 26px;
	background-color: transparent;
	border: 1px solid #ffffff;
	color: #ffffff;
	font-family: 'Roboto', sans-serif;
	font-size: 10px;
	outline: none;
	cursor: pointer;
}

.Event .BottomContainer .Register:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.Event .BottomContainer .Register:active {
	background-color: rgba(255, 255, 255, 0.9);
}

.Event .LeftIcon {
	width: 34px;
	height: 34px;
}

.Event .BottomContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Event .Right {
	display: grid;
	width: 350px;
	height: 100%;
	background-color: rgb(255, 255, 255);
	font-family: 'Roboto', sans-serif;
	font-size: 12.75px;
	color: #444444;
	padding: 0px 20px;
	align-content: center;
	box-sizing: border-box;
}

.Event .RightContainer {
	word-break: break-all;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.Event .RightIcon {
	width: 25.5px;
	height: 25.5px;
	margin-right: 5px;
}

/* calc(50px + (105 - 50) * ((100vw - 375px) / (1440 - 768))); */

@media only screen and (max-width: 1440px) {
	.Event {
		height: calc(100px + (140 - 100) * ((100vw - 375px) / (1440 - 375)));
		margin-left: calc(0px + (30 - 0) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(0px + (30 - 0) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .Left {
		width: calc(137px + (160 - 137) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .Right {
		width: calc(200px + (270 - 200) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(11px + (13 - 11) * ((100vw - 375px) / (1440 - 375)));
		padding: 0px calc(10px + (20 - 10) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .RightContainer {
		margin-bottom: 10px;
	}
	.Event .LeftIcon {
		width: calc(23px + (34 - 23) * ((100vw - 375px) / (1440 - 375)));
		height: calc(23px + (34 - 23) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .RightIcon {
		width: calc(22px + (25 - 22) * ((100vw - 375px) / (1440 - 375)));
		height: calc(22px + (25 - 22) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .DateMonth {
		font-size: calc(17px + (27 - 17) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: 5px;
	}
	.Event .DayTime {
		font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .BottomContainer .Register {
		width: calc(65px + (84 - 65) * ((100vw - 375px) / (1440 - 375)));
		height: calc(20px + (26 - 20) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(8px + (10 - 8) * ((100vw - 375px) / (1440 - 375)));
	}
}

/* calc(50px + (105 - 50) * ((100vw - 375px) / (1440 - 768))); */

@media only screen and (max-width: 764px) {
	.Event {
		height: calc(110px + (140 - 110) * ((100vw - 375px) / (764 - 375)));
		margin: 0px;
	}
	.Event:first-child {
		margin: 0px;
	}
	.Event:last-child {
		margin-bottom: 15px
	}
	.Event .Left {
		width: calc(137px + (160 - 137) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .Right {
		width: calc(210px + (270 - 210) * ((100vw - 375px) / (764 - 375)));
		font-size: calc(11px + (13 - 11) * ((100vw - 375px) / (764 - 375)));
		padding: 0px calc(10px + (20 - 10) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .RightContainer {
		margin-bottom: 5px;
	}
	.Event .LeftIcon {
		width: calc(23px + (34 - 23) * ((100vw - 375px) / (764 - 375)));
		height: calc(23px + (34 - 23) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .RightIcon {
		width: calc(22px + (25 - 22) * ((100vw - 375px) / (764 - 375)));
		height: calc(22px + (25 - 22) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .DateMonth {
		font-size: calc(17px + (27 - 17) * ((100vw - 375px) / (764 - 375)));
		margin-bottom: 5px;
	}
	.Event .DayTime {
		font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .BottomContainer .Register {
		width: calc(65px + (84 - 65) * ((100vw - 375px) / (764 - 375)));
		height: calc(20px + (26 - 20) * ((100vw - 375px) / (764 - 375)));
		font-size: calc(8px + (10 - 8) * ((100vw - 375px) / (764 - 375)));
	}
}