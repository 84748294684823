.container {
  width: 1050px;
  margin: auto auto;
  margin-bottom: 200px;
}

.headingContainer {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}
.heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.05em;

  color: #2f4454;
}

.helpIcon {
  margin-top: 20px;
}
@media (max-width: 600px) {
  .container {
    width: 100vw;
    margin: 0px;
    margin-bottom: 40px;
  }
  .headingContainer {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .heading {
    font-size: 14px;
  }
  .helpIcon {
    margin-top: 0px;
    width: 36px;
  }
}
