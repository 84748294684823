.IntroductionSlide {
	width: 100%;
	height: 100%;
	background: #f5f5f5 url("../icons/PolygonBackground.svg");
	background-size: contain;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Roboto;
	font-weight: normal;
	color: #494949;
	overflow: hidden;
}
.IntroductionSlide .TextContainer {
	width: 550px;
	margin-right: 50px;
}
.IntroductionSlide .TextContainer .H3 {
	font-size: 27px;
	margin: 0px;
	margin-bottom: 22px;
}
.IntroductionSlide .TextContainer .H1 {
	font-weight: bold;
	font-size: 60px;
	margin: 0px;
	margin-bottom: 46px;
}
.IntroductionSlide .TextContainer .H2 {
	font-size: 30px;
	color: #343434;
	margin: 0px;
}
.IntroductionSlide .TextContainer .H2 span {
	font-weight: bolder;
}

.IntroductionSlide .ImageCircleContainer {
	width: 575px;
	height: auto;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 170px;
}
.IntroductionSlide .ImageCircleContainer .Content {
	position: absolute;
	width: 550px;
}
/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1440px) {
	.IntroductionSlide .TextContainer {
		width: calc(130px + (550 - 130) * ((100vw - 375px) / (1440 - 375)));
		margin-right: calc(3px + (50 - 3) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .TextContainer .H3 {
		font-size: calc(10px + (27 - 10) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(5px + (22 - 5) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .TextContainer .H1 {
		font-size: calc(15px + (66 - 15) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(8px + (46 - 8) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .TextContainer .H2 {
		font-size: calc(8px + (30 - 8) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .ImageCircleContainer {
		width:  calc(135px + (575 - 135) * ((100vw - 375px) / (1440 - 375)));
		margin-top: calc(35px + (170 - 35) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .ImageCircleContainer .Content {
		width: calc(135px + (550 - 135) * ((100vw - 375px) / (1440 - 375)));
	}
}