.PromotionalSlide * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: rgb(255, 255, 255);
}

.PromotionalSlide {
	display: grid;
	grid-template-columns: 1fr auto 281px max-content 281px auto 1fr;
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(47, 68, 84, 0.522), rgba(47, 68, 84, 0.522)), url('../images/SlideTwoBackground.jpg');
	background-size: cover;
	background-position: center;
	overflow: hidden;
}

.Message {
	align-self: flex-end;
	grid-column: 2/7;
	font-weight: 900;
	font-size: 42px;
	text-align: center;
	color: rgba(255, 255, 255, 0.902);
	width: 1180px;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.051);
}

.DateTime {
	justify-self: flex-end;
	grid-column: 3;
	font-weight: bold;
	font-size: 18px;
}

.Location {
	justify-self: flex-start;
	grid-column: 5;
	font-weight: bold;
	font-size: 18px;
}

.SubMessage {
	grid-column: 4;
	font-weight: 400;
	font-size: 30px;
	width: max-content;
}

.Register {
	align-self: flex-start;
	grid-column: 4;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(96, 146, 184);
	width: max-content;
	height: 50px;
	border: none;
	outline: none;
	font-size: 18px;
	font-weight: 500;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.251);
	padding: 5px 10px;
}

.Register:active {
	background: rgb(116, 155, 185);
}

.Register img {
	height: 100%;
	width: 47px;
	border-right: 1.5px solid rgba(0, 0, 0, 0.102);
	margin-right: 7px;
	padding-right: 7px;
}

/* Medium devices (landscape tablets, 768px and up) */

/* calc(150px + (500 - 150) * ((100vw - 375px) / (1260 - 375))); */

@media only screen and (max-width: 1260px) {
	.PromotionalSlide {
		grid-template-columns: 1fr auto calc(140px + (281 - 140) * ((100vw - 375px) / (1260 - 375))) auto calc(140px + (281 - 140) * ((100vw - 375px) / (1260 - 375))) auto 1fr;
	}
	.Message {
		width: calc(280px + (1180 - 280) * ((100vw - 375px) / (1260 - 375)));
		font-size: calc(10px + (37 - 10) * ((100vw - 375px) / (1260 - 375)));
	}
	.SubMessage {
		font-size: calc(10px + (30 - 10) * ((100vw - 375px) / (1260 - 375)));
	}
	.DateTime {
		font-size: calc(7px + (18 - 7) * ((100vw - 375px) / (1260 - 375)));
	}
	.Location {
		font-size: calc(7px + (18 - 7) * ((100vw - 375px) / (1260 - 375)));
	}
	.Register {
		font-size: calc(5px + (15 - 5) * ((100vw - 375px) / (1260 - 375)));
		height: calc(16px + (51 - 16) * ((100vw - 375px) / (1260 - 375)));
		padding: 0px calc(5px + (10 - 5) * ((100vw - 375px) / (1260 - 375)));
	}
	.Register img {
		width: calc(20px + (47 - 20) * ((100vw - 375px) / (1260 - 375)));
		margin-right: calc(3px + (7 - 3) * ((100vw - 375px) / (1260 - 375)));
		padding-right: calc(3px + (7 - 3) * ((100vw - 375px) / (1260 - 375)));
	}
}