.Guarantee {
	display: grid;
	justify-items: center;
	height: max-content;
	background: white;
	font-family: "Roboto";
	font-size: 20px;
	font-weight: normal;
	overflow: hidden;
	padding-top: 150px;
	margin: auto;
    width: 100vw;
}

.Title {
	width: max-content;
	height: 105px;
	font-weight: bold;
	font-size: 48px;
	text-align: center;
	color: rgba(48, 48, 48, 0.88);
}

.SubTitle {
	width: 873px;
	font-size: inherit;
	line-height: 42px;
	text-align: center;
	margin: auto;
	margin-bottom: 100px;
	color: rgba(48, 48, 48, 0.7);
}

.Body {
	width: max-content;
	height: max-content;
	margin: auto;
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto;
	grid-gap: 150px;
	margin-bottom: 100px;
	opacity: 0;
}

.BodyText {
	width: max-content;
	height: 241px;
	font-size: inherit;
	line-height: 42px;
	color: rgba(48, 48, 48, 0.7);
	text-align: justify;
	animation-duration: 1.5s;
}

.Body:nth-child(even) .BodyText {
	grid-column: 2;
	grid-row: 1;
}

.BodyIcon {
	width: max-content;
	height: max-content;
	display: flex;
	opacity: 0;
	animation-duration: 1.5s;
}

.Body:nth-child(even) .BodyIcon {
	grid-column: 1;
	grid-row: 1;
}

.BodyText p {
	margin: 0px;
}

/* Medium devices (landscape tablets, 768px and up) */
/* calc(150px + (500 - 150) * ((100vw - 375px) / (1440 - 375))); */
@media only screen and (max-width: 1440px) {
	.Guarantee {
		padding-top: calc(100px + (150 - 100) * ((100vw - 375px) / (1440 - 375)));
	}
	.Title {
		height: calc(50px + (105 - 50) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(26px + (48 - 26) * ((100vw - 375px) / (1440 - 375)));
	}

	.SubTitle {
		width: calc(355px + (873 - 355) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
		line-height: calc(25px + (42 - 25) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(36px + (100 - 6) * ((100vw - 375px) / (1440 - 375)));
	}

	.Body {
		grid-gap: calc(0px + (150 - 0) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(40px + (50 - 40) * ((100vw - 375px) / (1440 - 375)));
	}

	.Body:last-child {
		margin-bottom: 100px;

	}

	.BodyText {
		line-height: calc(25px + (42 - 25) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
	}
}

@media only screen and (max-width: 767px) {
	.Body {
		justify-items: center;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		margin-bottom: calc(40px + (60 - 40) * ((100vw - 375px) / (767 - 375)));
		grid-gap: calc(40px + (60 - 40) * ((100vw - 375px) / (767 - 375)));
	}
	
	.BodyText{
		width: calc(340px + (500 - 340) * ((100vw - 375px) / (767 - 375)));
		height:100%;
		display: inline-block;
	}

	.Body:nth-child(even) .BodyText {
		grid-column: auto;
		grid-row: auto;
	}

	.Body:nth-child(even) .BodyIcon {
		grid-column: auto;
		grid-row: auto;
	}

	.BodyText p {
		display: initial;
		margin-right: 6px;
	}
}
