body{
   margin: 0;
}

.GridView {
	margin: auto;
    max-width: 1080px; 
    position: relative;

}

.mainContainer{
    position: relative;
    width:100%; 
    height:550px;
    background-color: #2F4454;
    margin:auto;
}

  .textContainer{
    width: 600px;
    height:500px;
    float: left;
}
.headerTitle{ 
    font-family: "Roboto";
    width: 150px;
    font-size: 40px; 
    padding: 200px 0px 0px 40px;  
}
.headerCont{
    font-family: "Roboto";
    color:#FFFFFF;
}
.headerText{
    width: 550px;
    font-size: 35px; 
    padding: 40px 0px 0px 40px ; 
    opacity: .8;
}

.headerImgCont{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100px;
    background-image: url("../images/confRoomTest.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 15%;
    width: 400px;
    height: 500px;

}
.headerImgCont::after{
    content: "";
    background: #2f445467;
    position: absolute;
    border-top-left-radius: 15%;
    width: 400px;
    height: 500px;
    z-index: 1;
}
@media (max-width:600px){
    .GridView {
       width: 100%; 
       height: 100%;
       position: relative;
    }
    .mainContainer{
        position: relative;
        background-size: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height:290px;
        width:100vw;
        background-color: white;
    }
    
    .textContainer{
        position: relative;
        width: 0px;
        height:200px;
        float: left;
        z-index: 2;
    }
    .headerTitle{ 
        font-size: 26px; 
        padding: 150px 0px 0px 60px;  
    }
    .headerText{
        width: 300px;
        font-size: 18px; 
        padding: 15px 0px 0px 60px ; 
    }

    .headerImgCont {
        position: relative; 
        width: inherit;
        height: inherit;
        padding-top:0px;
        padding-left:0px;
    }
   
    .headerImgCont{
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0px;
        background-image: url("../images/confRoomTest.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100vw;
        border-top-left-radius: 0%;
    
    }
    .headerImgCont::after{
        content: "";
        background: #2f445467;
        position: absolute;
        width: 100vw;
        z-index: 1;
        border-top-left-radius: 0%;
    }
 

}