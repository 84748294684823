.GridView {
	margin: auto auto;
	max-width: 1080px;
}
.mainContainer {
	width: max-content;
	height: max-content;
	margin-top: 100px;
	margin-bottom: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.ContactUsContainer{
	width: max-content;
	height: max-content;
	margin-top: 100px;
	margin-bottom: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.trainingContainer {
	display: grid;
	grid-template-columns: 500px auto;
	margin:auto;
	margin-top: 100px;
}
.infoTitle {
	justify-self: start;
	font-family: "Roboto",sans-serif;
	font-weight: bold;
	color: #333333;
	font-size: 35px;
	line-height: 50px;
	grid-column: 2/3;
	margin-left: 60px;
}

.textImgWrap{
	grid-column: 1/3;
}
.clsRoomImg {
	width: 500px;
	height: auto;
	float: left;
	margin-top: -90px;
	margin-right: 60px;
}
.content {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 80%;
	grid-gap: 10px 0px;
	font-family: "Roboto",sans-serif;
	color: #666666;
	font-size: 18px;
	font-weight: bold;
	line-height: 30px;
}
.contContactUS {
	position: relative;
	width: 550px;
}
.contContactUSText {
	position: relative;
	width: 550px;
}
.topicContent {
	width: 50%;
	float: left;
	font-family: "Roboto",sans-serif;
	margin-right: 70px;
}
.contactUsText {
	font-family: "Roboto",sans-serif;
	font-weight: bold;
	width: 500px;
	font-size: 30px;
	line-height: 1.6;
}
.topicOptions {
	font-family: "Roboto",sans-serif;
	width: 100%;
	font-weight: bold;
	opacity: .9;
}
.teachingPic {
	width: 600px;
	height: auto;
	clip-path: inset(0 0 20% 0);
}
.highlightText {
	color: #6092b8;
	font-weight: bold;
}
.contactBtn {
	color: #ffffff;
	font-size: 18px;
	background-color: #6092b8;
	border: none;
	height: 50px;
	width: 250px;
	border-radius: 25px;
	margin-top: 30px;
	outline: none;
}

.contactBtn:focus {
	box-shadow: 0 0 2pt 2pt #a9daff;
}

.individualTopic {
	padding: 5px 0px 10px 0;
	
	width: max-content;
	height: max-content;
	font-family: "Roboto",sans-serif;
	font-size: medium;
	opacity: .9;
	color: #000000;
	display: flex;
}
.botTopPadding {
	padding-bottom: 60px;
	padding-top: 100px;
}
.topicTriangle {
	width: 13px;
	height: auto;
	float: left;
	padding-right: 10px;
	display: flex;
	align-content: center;
	opacity: 1;
}

.circlePaperImg {
	width: 230px;
	margin-top: 120px;
	margin-left: 80px;	
}
.topicTitle {
	width: 600px;
	font-weight: bold;
	color: #333333;
	font-size: 35px;
	font-family: "Roboto",sans-serif;
	
}
@media (max-width:600px){

	.GridView {
		margin:auto;
		width: 100vw; 
	 }
	 .trainingContainer {
		grid-template-columns:auto;
		margin-top: 20px;
		justify-content: center;
		align-content: center;	
	}
	.infoTitle {
		grid-column: 1/2;
		justify-self: center;
		font-size: 22px;
		margin-left: 0px;
		text-align: center;
		width: 395px;
		margin-bottom: 10px;
	}
	.textImgWrap{
		grid-column: 1/2;
		margin-left: 20px;
		margin-right: 20px;
	}
	.clsRoomImg {
		width: 52vw;
		margin-top: 0px;
		margin: 15px;
	}
	.content {
	display: block;
	}

	.mainContainer {
		width:100vw;
		position: relative;
		margin-top: 20px;
		margin-bottom: 50px;
	}
	.ContactUsContainer{
		display: grid;
		grid-template-areas: "contactImage" 
							"contactText";
		width: 100vw;
		padding-bottom: 0px;
		margin-top:0px;
		margin-bottom: 0px;
		height:max-content;
		width: max-content;
		justify-content: center;
		align-items: center;
		text-align: center;
	}


	.topicContent {
		width: 100vw ;
		margin-left: 10px;
		margin-right: 0px;
		
	}
	.topicTitle {
		text-align: center;
		width: 100vw;
		font-size: 22px;
		text-align: center;
		color: #666666;
	}
	.botTopPadding {
		padding-bottom: 30px;
		padding-top: 50px;
	}
	.individualTopic {
		font-size: calc(14px + (20 - 14) * ((100vw - 414px) / (600 - 414)));
	}

	.teachingPic {
		width: 100wv;
	}
	.circlePaperImg {
		position: absolute;
		bottom:-10px;
		right:60px;
		width: 80px;
		padding: 0px;
		padding-top: 0px;
	}

	.topicTriangle {
		width: 8px;
	}

	.contContactUS {
		width: 100vw;
		grid-area: contactImage;
		height: max-content;
	}
	.contContactUSText {
		position: relative;
		top:-60px;
		grid-area: contactText;
		width: 100vw;	
	}
	
	.contactUsText {
		margin:auto;
		font-size: calc(20px + (30 - 20) * ((100vw - 414px) / (600 - 414)));
		width:calc(340px + (470 - 340) * ((100vw - 414px) / (600 - 414)));
		
	}
	.teachingPic {
		width: 100vw;
		height: auto;
		clip-path: inset(0 0 20% 0);
	}
}
