.Header {
  width: 100%;
  height: 65px;
  background-color: #21303c;
  transition: top 200ms ease-in;
  top: 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: 65px;
  grid-template-areas: "logo navbar";
  justify-items: center;
}
.HeaderTopOffSet {
  top: -80px;
  transition: top 300ms ease-in;
}
.HeaderPositionFixed {
  position: fixed !important;
  z-index: 7;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.25);
}
.BodyMargin > div:nth-child(2) {
  margin-top: 70px;
}

.Header .GridView {
  margin: auto;
  max-width: 1120px;
}

.Header > a {
  grid-area: logo;
}

.Header-navBar {
  grid-area: navbar;
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: center;
  justify-items: center;
  grid-column-gap: 33px;
}

.Header-navBar a {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  height: max-content !important;
  width: 100%;
}

.Header-navBar p {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  height: max-content !important;
  cursor: pointer;
  width: 100%;
}

.Header-Logo {
  width: 85px;
  height: 60px;
}

.hamburger {
  grid-area: hamburger;
  display: none;
  cursor: pointer;
}

.mobile-bars {
  margin-right: 20px;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.4s;
}

@media screen and (max-width: 600px) {
  .hamburger {
    -webkit-perspective: 900000px;
    perspective: 900000px;
    display: inline-block;
    align-self: center;
    justify-self: end;
  }
  .Header {
    display: grid;
    position: relative;
    grid-template-columns: auto auto;
    grid-template-rows: 60px auto;
    grid-template-areas:
      "logo hamburger"
      "navbar filler";
    justify-items: center;
  }
  .Header > a {
    grid-area: logo;
    justify-self: start;
    margin-left: 20px;
  }

  .Header-navBar {
    grid-area: navbar;
    position: absolute;
    background-color: #21303c;
    grid-column: 1/3;
    align-self: stretch;
    width: 100%;
    height: 100vh;
    right: -100%;
    z-index: 7;
    transition: all 300ms linear;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-auto-rows: 50px;
    align-items: center;
    justify-items: center;
  }

  .open {
    right: 0;
  }
  .Header-navBar a {
    border-bottom: 1px solid rgb(249, 249, 249, 0.3);
    border-bottom: 1px solid rgba(249, 249, 249, 0.3);
    font-size: 20px;
    line-height: 50px;
    height: 100% !important;
  }
  .Header-navBar p {
    border-bottom: 1px solid rgb(249, 249, 249, 0.3);
    border-bottom: 1px solid rgba(249, 249, 249, 0.3);
    padding-bottom: 5px;
    font-size: 20px;
    line-height: 23px;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
}
