.input {
	width: 100%;
	height: 50px;
	position: relative;
	font-family: "Roboto", sans-serif;
}
.input input {
	color: #2f4454;
	border: none;
	border-bottom: solid 2px #2f4454;
	font-size: 20px;
	font-family: sans-serif;
	outline: none;
	width: 100%;
	height: 70%;
	position: absolute;
	bottom: 0%;
	left: 0%;
}
.input label {
	pointer-events: none;
	align-items: center;
	display: flex;
	width: 100%;
	height: 70%;
	position: absolute;
	bottom: 0%;
	left: 0%;
}
.input label::after {
	border-bottom: solid 2px #2f4454;
	content: "";
	display: block;
	width: 100%;
	height: 70%;
	position: absolute;
	bottom: 0%;
	left: 0%;
	transform: scaleX(0);
	transition: transform 250ms ease-in-out;
}
.input input:focus + label:after,
.input input:not(:placeholder-shown) + label:after {
	transform: scaleX(1);
}
.input label span {
	color: #2f4454;
	text-align: center;
	width: 100%;
	font-size: 20px;
	font-family: sans-serif;
	transition: all 0.3s ease;
}
.input label .error {
	color: #ff0000 !important;
}
.input label .error {
	color: #ff0000 !important;
}
.input input:focus + label span,
.input input:not(:placeholder-shown) + label span {
	transform: translateY(-160%);
	font-size: 13px;
	color: #2f4454;
}


@media (max-width:600px){
	.input label::after {
		border-bottom: solid 2px #2f4454;
		content: "";
		display: block;
		width: 100%;
		height: 70%;
	
		bottom: 0%;
		left: 0%;
		transform: scaleX(0);
		transition: transform 250ms ease-in-out;



	}
	.input label {
		pointer-events: none;
		align-items: center;
		
		width: 100%;
		height: 70%;
		position: absolute;
		bottom: 0%;
		left: 0%;

		display: block;

	}
	.input label span {
		color: #2f4454;
		text-align: center;
		width: 100%;
	
		font-family: sans-serif;
		transition: all 0.3s ease;

		font-size: 14px;
	
	}
	.input input:focus + label span,
	.input input:not(:placeholder-shown) + label span {
	font-size: 11px;
	}

}