.question {
  margin-top: 30px;
  width: 1050px;
  height: 44px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.05em;
  text-align: left;
  cursor: pointer;

  color: #2f4454;
}

.answer {
  max-height: 124px;
  transition: max-height 400ms ease-out;
}

.answerHide {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 200ms ease-out;
}

.answerText {
  width: 950px;
  margin: auto auto;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.05em;
  color: rgba(47, 68, 84, 0.8);
}

.openIcon {
  width: 21px;
  height: 21px;
  float: right;
  transform: rotate(-90deg) scale(0.75);
  transition: transform 300ms ease-out;
}

.closedIcon {
  width: 21px;
  height: 21px;
  float: right;
  transition: transform 300ms ease-out;
}

@media (max-width: 600px) {
  .question {
    width: 90vw;
    margin-left: 20px;
  }
  .answerText {
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
    width: fit-content;
    hyphens: auto;
  }
}
