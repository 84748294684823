.pageTesContainer {
	position: relative;
	height: max-content;
	background-color: #2f4454;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100vw;
    margin: auto;
}

.title {
	font-family: "Roboto", sans-serif;
	text-align: left;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #444444;
	height: 120px;
	font-size: 30px;
	width: 100%;
	background: #ffffff;
	letter-spacing: 0.1em;
}

.TextCarousel {
	width: 1000px;
	height: 250px;
	background-color: #f5f5f500;
	position: relative;
	overflow: hidden;
	margin: 0px 0px 60px 0px;
}

@media only screen and (max-width: 1024px) {
	.TextCarousel{
		width: calc(700px + (1000 - 700) * ((100vw - 768px) / (1024 - 768)));
		height: calc(300px + (250 - 300) * ((100vw - 768px) / (1024 - 768)));
	}
}

@media only screen and (max-width: 767px) {
	.TextCarousel{
		width: calc(350px + (700 - 350) * ((100vw - 375px) / (767 - 375)));
		height: calc(510px + (370 - 510) * ((100vw - 375px) / (767 - 375)));
	}
	.TextCarousel > div:first-child{
		padding-top: 50px;
	}
}