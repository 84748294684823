.upcomingEvent .title {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444444;
  height: 120px;
  font-size: 30.6px;
  letter-spacing: 0.1em;
  background-color: #ffffff;
}

.upcomingEvent .container {
  background-color: #ececed;
  padding-top: 52px;
  padding-bottom: 22px;
  overflow: hidden;
}

.upcomingEvent .container .rows {
  margin: auto;
  width: min-content;
}

.upcomingEvent .container .rows .row {
  display: flex;
  justify-content: center;
  animation-duration: 0.5s;
  animation-fill-mode: none;
}

.upcomingEvent .container .rows .row2 {
  margin-left: 100px;
}
.upcomingEvent .container .rows .row3 {
  margin-right: 100px;
}

.upcomingEvent .container .ButtonContainer {
  width: 25%;
  max-width: 500px;
  margin: auto;
  justify-content: center;
  display: flex;
}

.upcomingEvent .container .ButtonContainer .LongButton {
  width: 80px;
  height: 10px;
  background-color: #a3a3a3;
  border-radius: 50px;
  min-width: 10px;
  margin: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}
.upcomingEvent .container .ButtonContainer .LongButtonActive {
  background-color: #2f4454;
}

@media only screen and (max-width: 950px) {
  .upcomingEvent .container .rows .row2 {
    margin-left: 0px;
  }
  .upcomingEvent .container .rows .row3 {
    margin-right: 0px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 764px) {
  .upcomingEvent .title {
    font-weight: bold;
    text-align: center;
    color: #444444;
    height: 100px;
    width: 100%;
    font-size: 25px;
    letter-spacing: 0.1em;
  }

  .upcomingEvent .container {
    overflow: scroll;
    padding: 15px 0px;
    max-height: calc(100vh - 10px);
    display: grid;
    justify-items: center;
    grid-gap: 15px;
    box-sizing: border-box;
  }
}
