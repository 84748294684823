.Event {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 140px;
	display: -webkit-flex;
	display: flex;
	text-align: left;
	margin-left: 30px;
	margin-bottom: 30px;
}

.Event:first-child {
	margin-left: 0px;
}

.Event .Left {
	color: #ffffff;
	width: 160px;
	height: 100%;
	padding: 10px 15px;
	background-color: #2f4454;
	display: grid;
	-webkit-align-content: space-between;
	        align-content: space-between;
	position: relative;
	box-shadow: 2.64888px 0px 17.6592px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
}

.Event .Left:after {
	content: "";
	float: left;
	position: absolute;
	top: 35%;
	right: -15px;
	width: 0;
	height: 0;
	border-color: transparent transparent transparent #2f4454;
	border-style: solid;
	border-width: 17px 0 17px 25px;
}

.Event .TopContainer {
	/* margin: 15px; */
}

.Event .DateMonth {
	font-family: 'Roboto', sans-serif;
	font-size: 27px;
	margin: 0px;
	margin-bottom: 5px;
}

.Event .DayTime {
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	text-align: left;
	font-weight: bold;
	color: rgba(242, 242, 242, 0.8);
	margin: 0px;
}

.Event .BottomContainer .Register {
	width: 84px;
	height: 26px;
	background-color: transparent;
	border: 1px solid #ffffff;
	color: #ffffff;
	font-family: 'Roboto', sans-serif;
	font-size: 10px;
	outline: none;
	cursor: pointer;
}

.Event .BottomContainer .Register:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.Event .BottomContainer .Register:active {
	background-color: rgba(255, 255, 255, 0.9);
}

.Event .LeftIcon {
	width: 34px;
	height: 34px;
}

.Event .BottomContainer {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}

.Event .Right {
	display: grid;
	width: 350px;
	height: 100%;
	background-color: rgb(255, 255, 255);
	font-family: 'Roboto', sans-serif;
	font-size: 12.75px;
	color: #444444;
	padding: 0px 20px;
	-webkit-align-content: center;
	        align-content: center;
	box-sizing: border-box;
}

.Event .RightContainer {
	word-break: break-all;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: 10px;
}

.Event .RightIcon {
	width: 25.5px;
	height: 25.5px;
	margin-right: 5px;
}

/* calc(50px + (105 - 50) * ((100vw - 375px) / (1440 - 768))); */

@media only screen and (max-width: 1440px) {
	.Event {
		height: calc(100px + (140 - 100) * ((100vw - 375px) / (1440 - 375)));
		margin-left: calc(0px + (30 - 0) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(0px + (30 - 0) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .Left {
		width: calc(137px + (160 - 137) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .Right {
		width: calc(200px + (270 - 200) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(11px + (13 - 11) * ((100vw - 375px) / (1440 - 375)));
		padding: 0px calc(10px + (20 - 10) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .RightContainer {
		margin-bottom: 10px;
	}
	.Event .LeftIcon {
		width: calc(23px + (34 - 23) * ((100vw - 375px) / (1440 - 375)));
		height: calc(23px + (34 - 23) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .RightIcon {
		width: calc(22px + (25 - 22) * ((100vw - 375px) / (1440 - 375)));
		height: calc(22px + (25 - 22) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .DateMonth {
		font-size: calc(17px + (27 - 17) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: 5px;
	}
	.Event .DayTime {
		font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (1440 - 375)));
	}
	.Event .BottomContainer .Register {
		width: calc(65px + (84 - 65) * ((100vw - 375px) / (1440 - 375)));
		height: calc(20px + (26 - 20) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(8px + (10 - 8) * ((100vw - 375px) / (1440 - 375)));
	}
}

/* calc(50px + (105 - 50) * ((100vw - 375px) / (1440 - 768))); */

@media only screen and (max-width: 764px) {
	.Event {
		height: calc(110px + (140 - 110) * ((100vw - 375px) / (764 - 375)));
		margin: 0px;
	}
	.Event:first-child {
		margin: 0px;
	}
	.Event:last-child {
		margin-bottom: 15px
	}
	.Event .Left {
		width: calc(137px + (160 - 137) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .Right {
		width: calc(210px + (270 - 210) * ((100vw - 375px) / (764 - 375)));
		font-size: calc(11px + (13 - 11) * ((100vw - 375px) / (764 - 375)));
		padding: 0px calc(10px + (20 - 10) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .RightContainer {
		margin-bottom: 5px;
	}
	.Event .LeftIcon {
		width: calc(23px + (34 - 23) * ((100vw - 375px) / (764 - 375)));
		height: calc(23px + (34 - 23) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .RightIcon {
		width: calc(22px + (25 - 22) * ((100vw - 375px) / (764 - 375)));
		height: calc(22px + (25 - 22) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .DateMonth {
		font-size: calc(17px + (27 - 17) * ((100vw - 375px) / (764 - 375)));
		margin-bottom: 5px;
	}
	.Event .DayTime {
		font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (764 - 375)));
	}
	.Event .BottomContainer .Register {
		width: calc(65px + (84 - 65) * ((100vw - 375px) / (764 - 375)));
		height: calc(20px + (26 - 20) * ((100vw - 375px) / (764 - 375)));
		font-size: calc(8px + (10 - 8) * ((100vw - 375px) / (764 - 375)));
	}
}
.Modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Show {
  display: -webkit-flex;
  display: flex;
}
.Hide {
  display: none;
}

.input {
	width: 100%;
	height: 50px;
	position: relative;
	font-family: "Roboto", sans-serif;
}
.input input {
	color: #2f4454;
	border: none;
	border-bottom: solid 2px #2f4454;
	font-size: 20px;
	font-family: sans-serif;
	outline: none;
	width: 100%;
	height: 70%;
	position: absolute;
	bottom: 0%;
	left: 0%;
}
.input label {
	pointer-events: none;
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	height: 70%;
	position: absolute;
	bottom: 0%;
	left: 0%;
}
.input label::after {
	border-bottom: solid 2px #2f4454;
	content: "";
	display: block;
	width: 100%;
	height: 70%;
	position: absolute;
	bottom: 0%;
	left: 0%;
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	transition: -webkit-transform 250ms ease-in-out;
	transition: transform 250ms ease-in-out;
	transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}
.input input:focus + label:after,
.input input:not(:placeholder-shown) + label:after {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}
.input label span {
	color: #2f4454;
	text-align: center;
	width: 100%;
	font-size: 20px;
	font-family: sans-serif;
	transition: all 0.3s ease;
}
.input label .error {
	color: #ff0000 !important;
}
.input label .error {
	color: #ff0000 !important;
}
.input input:focus + label span,
.input input:not(:placeholder-shown) + label span {
	-webkit-transform: translateY(-160%);
	        transform: translateY(-160%);
	font-size: 13px;
	color: #2f4454;
}


@media (max-width:600px){
	.input label::after {
		border-bottom: solid 2px #2f4454;
		content: "";
		display: block;
		width: 100%;
		height: 70%;
	
		bottom: 0%;
		left: 0%;
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
		transition: -webkit-transform 250ms ease-in-out;
		transition: transform 250ms ease-in-out;
		transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;



	}
	.input label {
		pointer-events: none;
		-webkit-align-items: center;
		        align-items: center;
		
		width: 100%;
		height: 70%;
		position: absolute;
		bottom: 0%;
		left: 0%;

		display: block;

	}
	.input label span {
		color: #2f4454;
		text-align: center;
		width: 100%;
	
		font-family: sans-serif;
		transition: all 0.3s ease;

		font-size: 14px;
	
	}
	.input input:focus + label span,
	.input input:not(:placeholder-shown) + label span {
	font-size: 11px;
	}

}
.Textarea {
	width: 100%;
	height: 100%;
	position: relative;
	font-family: 'Roboto', sans-serif;
	outline: none;
}
.Textarea .Textarea-Input {
	color: #2f4454;
	border: none;
	border-bottom: solid 2px #2f4454;
	font-size: 14px;
	font-family: sans-serif;
	outline: none;
	width: 100%;
	Height: 100%;
	position: absolute;
	bottom: 0%;
	left: 0%;
	resize: none;
}
.Textarea .Textarea-placeholder {
	pointer-events: none;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
	Height: 100%;
	padding-bottom: 5px;
	position: absolute;
	bottom: 0%;
	left: 0%;
}
.Textarea .Textarea-placeholder::after {
	border-bottom: solid 2px #2f4454;
	content: "";
	display: block;
	width: 100%;
	Height: 100%;
	position: absolute;
	bottom: 0%;
	left: 0%;
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	transition: -webkit-transform 250ms ease-in-out;
	transition: transform 250ms ease-in-out;
	transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}

.Textarea .Textarea-Input:focus + .Textarea-placeholder:after,
.Textarea .Textarea-Input:not(:placeholder-shown) + .Textarea-placeholder:after {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}

.Textarea .Textarea-placeholder .Textarea-placeholder-text {
	font-size: 20px;
	color: #2f4454;
	font-family: sans-serif;
	transition: all 0.3s ease;
}

.Textarea .Textarea-Input:focus + .Textarea-placeholder .Textarea-placeholder-text,
.Textarea .Textarea-Input:not(:placeholder-shown) + .Textarea-placeholder .Textarea-placeholder-text {
	transition: opacity 250ms ease-out;
	opacity: 0;
}
@media (max-width:600px){
	.Textarea .Textarea-placeholder .Textarea-placeholder-text {
		
		color: #2f4454;
		font-family: sans-serif;
		transition: all 0.3s ease;


		font-size: 12px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.05em;
		
		color: #2F4454;
		
		opacity: 0.9;
	}
	.Textarea .Textarea-placeholder .Textarea-placeholder-text {
		
		color: #2f4454;
		font-family: sans-serif;
		transition: all 0.3s ease;

		position: absolute;
		top:40px;
		font-size: 12px;
	}
	
}
.Confirmation_Confirmation__1pnAa {
  width: 968px;
  height: 605.53px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Confirmation_Title__2_86F {
  text-align: center;
  font-weight: bold;
  font-size: 40.0191px;
  color: #2f4454;
  margin-bottom: 20px;
  width: 660px;
}
.Confirmation_SubTitle__2PfQY {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 25px;
  text-align: center;
  color: rgba(47, 68, 84, 0.8);
}
@media (max-width: 600px) {
  .Confirmation_Confirmation__1pnAa {
    width: 100vw;
    height: 300.53px;
  }
  .Confirmation_Title__2_86F {
    font-size: 25.0191px;
    width: 80vw;
  }
  .Confirmation_SubTitle__2PfQY {
    font-size: 15px;
  }
}

.RegisterForm {
  background-color: #ffffff;
  width: 1011.73px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  font-family: "Roboto", sans-serif;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.RegisterForm .CloseButton {
  float: right;
  margin: 6px;
  background-color: transparent;
  border: none;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.RegisterForm .SubTitle {
  width: 600px;
  height: 47.93px;
  font-family: inherit;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  text-align: center;
  color: rgba(47, 68, 84, 0.8);
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
}
.RegisterForm form {
  text-align: center;
  margin: auto;
  margin-top: 20px;
}
.RegisterForm form .Container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 778.05px;
  margin: auto;
}
.RegisterForm form .Container:nth-child(2) {
  -webkit-justify-content: start;
          justify-content: start;
}
.RegisterForm form .Container:nth-child(3) {
  -webkit-justify-content: center;
          justify-content: center;
}
.RegisterForm .Container .Input {
  width: 277.05px;
  height: 70px;
  margin-bottom: 20px;
}
.RegisterForm .Container .Textarea {
  width: 500px;
  height: 85px;
  margin-bottom: 70px;
  margin-top: 10px;
  outline: none;
}
.RegisterForm .FromSubmit {
  width: 200px;
  height: 40.36px;
  background-color: rgb(47, 68, 84);
  font-weight: 600;
  font-size: 18.1337px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  margin-bottom: 30px;
  border: none;
}
.RegisterForm .MessageContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0px 20px 0px 20px;
}
.RegisterForm .MessageContainer .message {
  font-weight: 100;
  font-size: 14.1337px;
  color: rgba(47, 68, 84, 0.7);
  text-align: right;
}
@media (max-width: 600px) {
  .RegisterForm {
    width: 100vw;
    padding-bottom: 20px;
  }
  .RegisterForm .SubTitle {
    font-size: 15px;
    margin-top: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 50.93px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .RegisterForm form {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 340px;
  }
  .RegisterForm form .Container {
    display: grid;
    grid-template-columns: "name" "email" "phone";
    grid-column-gap: 20px;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin: 0px;
  }
  .RegisterForm form .Container:nth-child(2) {
    -webkit-justify-content: start;
            justify-content: start;
    display: -webkit-flex;
    display: flex;
    margin: auto;
  }
  .RegisterForm form .Container:nth-child(3) {
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    margin: auto;
  }
  .RegisterForm .Container .Input {
    width: 80%;
    height: 55px;
    margin-bottom: 0px;
  }
  .RegisterForm .Container .Textarea {
    width: 80vw;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 15px;
  }
  .RegisterForm .MessageContainer {
    padding: 0px 0px 0px 0px;
    width: 100%;
    display: block;
  }
  .RegisterForm .MessageContainer .message {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 10px;
  }
}

.upcomingEvent .title {
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #444444;
  height: 120px;
  font-size: 30.6px;
  letter-spacing: 0.1em;
  background-color: #ffffff;
}

.upcomingEvent .container {
  background-color: #ececed;
  padding-top: 52px;
  padding-bottom: 22px;
  overflow: hidden;
}

.upcomingEvent .container .rows {
  margin: auto;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.upcomingEvent .container .rows .row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: none;
          animation-fill-mode: none;
}

.upcomingEvent .container .rows .row2 {
  margin-left: 100px;
}
.upcomingEvent .container .rows .row3 {
  margin-right: 100px;
}

.upcomingEvent .container .ButtonContainer {
  width: 25%;
  max-width: 500px;
  margin: auto;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.upcomingEvent .container .ButtonContainer .LongButton {
  width: 80px;
  height: 10px;
  background-color: #a3a3a3;
  border-radius: 50px;
  min-width: 10px;
  margin: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}
.upcomingEvent .container .ButtonContainer .LongButtonActive {
  background-color: #2f4454;
}

@media only screen and (max-width: 950px) {
  .upcomingEvent .container .rows .row2 {
    margin-left: 0px;
  }
  .upcomingEvent .container .rows .row3 {
    margin-right: 0px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 764px) {
  .upcomingEvent .title {
    font-weight: bold;
    text-align: center;
    color: #444444;
    height: 100px;
    width: 100%;
    font-size: 25px;
    letter-spacing: 0.1em;
  }

  .upcomingEvent .container {
    overflow: scroll;
    padding: 15px 0px;
    max-height: calc(100vh - 10px);
    display: grid;
    justify-items: center;
    grid-gap: 15px;
    box-sizing: border-box;
  }
}

.Guarantee_Guarantee__3Wijn {
	display: grid;
	justify-items: center;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	background: white;
	font-family: "Roboto";
	font-size: 20px;
	font-weight: normal;
	overflow: hidden;
	padding-top: 150px;
	margin: auto;
    width: 100vw;
}

.Guarantee_Title__1WcMa {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 105px;
	font-weight: bold;
	font-size: 48px;
	text-align: center;
	color: rgba(48, 48, 48, 0.88);
}

.Guarantee_SubTitle__2uWqc {
	width: 873px;
	font-size: inherit;
	line-height: 42px;
	text-align: center;
	margin: auto;
	margin-bottom: 100px;
	color: rgba(48, 48, 48, 0.7);
}

.Guarantee_Body__1yd2N {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin: auto;
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto;
	grid-gap: 150px;
	margin-bottom: 100px;
	opacity: 0;
}

.Guarantee_BodyText__2C9g3 {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 241px;
	font-size: inherit;
	line-height: 42px;
	color: rgba(48, 48, 48, 0.7);
	text-align: justify;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
}

.Guarantee_Body__1yd2N:nth-child(even) .Guarantee_BodyText__2C9g3 {
	grid-column: 2;
	grid-row: 1;
}

.Guarantee_BodyIcon__2gZol {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	display: -webkit-flex;
	display: flex;
	opacity: 0;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
}

.Guarantee_Body__1yd2N:nth-child(even) .Guarantee_BodyIcon__2gZol {
	grid-column: 1;
	grid-row: 1;
}

.Guarantee_BodyText__2C9g3 p {
	margin: 0px;
}

/* Medium devices (landscape tablets, 768px and up) */
/* calc(150px + (500 - 150) * ((100vw - 375px) / (1440 - 375))); */
@media only screen and (max-width: 1440px) {
	.Guarantee_Guarantee__3Wijn {
		padding-top: calc(100px + (150 - 100) * ((100vw - 375px) / (1440 - 375)));
	}
	.Guarantee_Title__1WcMa {
		height: calc(50px + (105 - 50) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(26px + (48 - 26) * ((100vw - 375px) / (1440 - 375)));
	}

	.Guarantee_SubTitle__2uWqc {
		width: calc(355px + (873 - 355) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
		line-height: calc(25px + (42 - 25) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(36px + (100 - 6) * ((100vw - 375px) / (1440 - 375)));
	}

	.Guarantee_Body__1yd2N {
		grid-gap: calc(0px + (150 - 0) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(40px + (50 - 40) * ((100vw - 375px) / (1440 - 375)));
	}

	.Guarantee_Body__1yd2N:last-child {
		margin-bottom: 100px;

	}

	.Guarantee_BodyText__2C9g3 {
		line-height: calc(25px + (42 - 25) * ((100vw - 375px) / (1440 - 375)));
		font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
	}
}

@media only screen and (max-width: 767px) {
	.Guarantee_Body__1yd2N {
		justify-items: center;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		margin-bottom: calc(40px + (60 - 40) * ((100vw - 375px) / (767 - 375)));
		grid-gap: calc(40px + (60 - 40) * ((100vw - 375px) / (767 - 375)));
	}
	
	.Guarantee_BodyText__2C9g3{
		width: calc(340px + (500 - 340) * ((100vw - 375px) / (767 - 375)));
		height:100%;
		display: inline-block;
	}

	.Guarantee_Body__1yd2N:nth-child(even) .Guarantee_BodyText__2C9g3 {
		grid-column: auto;
		grid-row: auto;
	}

	.Guarantee_Body__1yd2N:nth-child(even) .Guarantee_BodyIcon__2gZol {
		grid-column: auto;
		grid-row: auto;
	}

	.Guarantee_BodyText__2C9g3 p {
		display: inline;
		display: initial;
		margin-right: 6px;
	}
}

.BodyIcon_Container__149At {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin-right: 50px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	box-sizing: border-box;
}

.BodyIcon_Container__149At:last-child,
.BodyIcon_Container__149At:only-child {
	margin-right: 0px;
}

.BodyIcon_Icon__1Kx1Y {
	padding: 25px;
	background: #d4d4d434;
}

.BodyIcon_Text__1oRje {
	width: 157px;
	text-align: center;
	font-size: 17px;
	color: rgba(48, 48, 48, 0.7);
}
@media only screen and (max-width: 1440px) {

	.BodyIcon_Container__149At {
		margin-right: calc(10px + (50 - 10) * ((100vw - 768px) / (1440 - 768)));
	}

	.BodyIcon_Container__149At:last-child,
	.BodyIcon_Container__149At:only-child {
		margin-right: 0px;
	}

	.BodyIcon_Icon__1Kx1Y {
		padding: calc(15px + (25 - 15) * ((100vw - 768px) / (1440 - 768)));
		width: calc(70px + (100 - 70) * ((100vw - 768px) / (1440 - 768)));
		/* height: calc(90px + (100 - 90) * ((100vw - 768px) / (1440 - 768))); */
	}

	.BodyIcon_Text__1oRje {
		width: calc(80px + (150 - 80) * ((100vw - 768px) / (1440 - 768)));
		font-size: calc(11px + (17 - 11) * ((100vw - 768px) / (1440 - 768)));
	}
}

@media only screen and (max-width: 767px) {
.BodyIcon_Container__149At {
		margin-right:calc(20px + (30 - 20) * ((100vw - 375px) / (768 - 375)));
	}

	.BodyIcon_Text__1oRje {
		width: calc(80px + (150 - 80) * ((100vw - 375px) / (768 - 375)));
		font-size: calc(10px + (14 - 10) * ((100vw - 375px) / (768 - 375)));
	}
}
.GuaranteeVideo_GuaranteeVideo__1nq-f {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 100vw;
	height: 600px;
	background: transparent;
	overflow: hidden;
	margin: auto;
}

.GuaranteeVideo_Video__1NKPJ {
	width: 0px;
}

.GuaranteeVideo_GuaranteeVideoInnerShadow__DQaT6 {
	height: 100%;
	width: 100%;
	background-image: url(/static/media/office.5fea6bbb.jpeg);
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
}

.GuaranteeVideo_GuaranteeVideoInnerShadow__DQaT6::before {
	content: "";
	background: #2f445499;
	position: absolute;
	height: 100%;
	width: 100%;
	box-shadow: inset 0 0 10px #000000;
}

.GuaranteeVideo_PlayButton__32rle {
	width: 145px;
	height: 145px;
	background: transparent;
	border-radius: 50%;
	position: absolute;
	outline: none;
	border: none;
	-webkit-filter: drop-shadow(0 0 0.5rem #000000ad);
	        filter: drop-shadow(0 0 0.5rem #000000ad);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
}

/* pulse wave */
.GuaranteeVideo_PlayButton__32rle:before {
	content: "";
	position: absolute;
	width: 150%;
	height: 150%;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
	-webkit-animation: GuaranteeVideo_pulsate1__3XkBX 2s;
	        animation: GuaranteeVideo_pulsate1__3XkBX 2s;
	-webkit-animation-direction: forwards;
	        animation-direction: forwards;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-timing-function: steps;
	        animation-timing-function: steps;
	opacity: 1;
	border-radius: 50%;
	border: 5px solid rgba(255, 255, 255, .75);
	background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes GuaranteeVideo_pulsate1__3XkBX {
	0% {
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 1;
		box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
	}
	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1);
		opacity: 0;
		box-shadow: none;
	}
}

@keyframes GuaranteeVideo_pulsate1__3XkBX {
	0% {
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 1;
		box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
	}
	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1);
		opacity: 0;
		box-shadow: none;
	}
}

@media only screen and (max-width: 1440px) {
	.GuaranteeVideo_GuaranteeVideo__1nq-f {
		height: calc(400px + (600 - 400) * ((100vw - 375px) / (1440 - 375)));
	}
	.GuaranteeVideo_GuaranteeVideoInnerShadow__DQaT6{
		background-image: url(/static/media/officeM.f1edd956.jpg);
		background-attachment: scroll;
	}
	.GuaranteeVideo_PlayButton__32rle {
		width: calc(100px + (145 - 100) * ((100vw - 375px) / (1440 - 375)));
		height: calc(100px + (145 - 100) * ((100vw - 375px) / (1440 - 375)));
	}
}
.Carousel_Carousel__1IvHf {
  width: 100vw;
  height: 500px;
  background-color: #f5f5f500;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  margin: auto;
}
.Carousel_Slides__17Kj_ {
  width: 100%;
  height: 100%;
  background-color: #f5f5f500;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
}
.Carousel_SlideWrapper__3Ykvk {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.Carousel_arrowButtonContainer__6LqAM {
  max-width: 1680px;
  width: 100vw;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Carousel_arrowButton__3mVh5 {
  width: 83px;
  height: 83px;
  padding: 0px 0px 0px 9px;
  background: rgba(158, 158, 158, 0.4);
  border: none;
  border-radius: 50%;
  margin: 0px 30px;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}
.Carousel_arrowButtonHide__af-Vz {
  display: none;
}

.Carousel_arrowButton__3mVh5:active {
  background: #ffffff79;
}

.Carousel_arrowButton__3mVh5:first-child {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Carousel_arrowButtonIconContainer__2A7OM {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-filter: drop-shadow(0px 3.70062px 13.2165px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 3.70062px 13.2165px rgba(0, 0, 0, 0.5));
}

.Carousel_EEER__3FAMQ {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 90px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  bottom: -45px;
  background: #f5f5f5;
  box-shadow: 0px 3.70062px 13.2165px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 0px 40px;
}
.Carousel_EEER__3FAMQ p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  background: -webkit-linear-gradient(
    45deg,
    #da5858,
    #5491ed,
    #dac760,
    #4aa759 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Carousel_DotButtonContainer__2C43g {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  bottom: 0;
}
.Carousel_DotButton__OFmzL {
  width: 14px;
  height: 14px;
  margin: 0px 10px 10px 0px;
  border-radius: 50%;
  border: none;
  background: #cecece;
  outline: none;
}
.Carousel_DotButton__OFmzL:last-child {
  margin-right: 0px;
}
.Carousel_DotButtonActive__MtaX6 {
  background: #969696;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1440px) {
  .Carousel_Carousel__1IvHf {
    height: calc(150px + (500 - 150) * ((100vw - 375px) / (1440 - 375)));
  }

  .Carousel_EEER__3FAMQ {
    height: calc(36px + (90 - 36) * ((100vw - 375px) / (1440 - 375)));
    bottom: calc(-18px + (-45 + 18) * ((100vw - 375px) / (1440 - 375)));
    padding: 0px calc(15px + (40 - 15) * ((100vw - 375px) / (1440 - 375)));
  }

  .Carousel_EEER__3FAMQ p {
    font-size: calc(12px + (30 - 12) * ((100vw - 375px) / (1440 - 375)));
  }
  .Carousel_arrowButton__3mVh5 {
    width: calc(20px + (83 - 20) * ((100vw - 375px) / (1440 - 375)));
    height: calc(20px + (83 - 20) * ((100vw - 375px) / (1440 - 375)));
    padding: 0px 0px 0px calc(2px + (9 - 2) * ((100vw - 375px) / (1440 - 375)));
    margin: 0px calc(20px + (30 - 20) * ((100vw - 375px) / (1440 - 375)));
  }
  .Carousel_arrowButtonIcon__3nhMA {
    width: calc(15px + (55 - 15) * ((100vw - 375px) / (1440 - 375)));
    height: calc(15px + (55 - 15) * ((100vw - 375px) / (1440 - 375)));
  }
}

.IntroductionSlide {
	width: 100%;
	height: 100%;
	background: #f5f5f5 url(/static/media/PolygonBackground.d647a956.svg);
	background-size: contain;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-family: Roboto;
	font-weight: normal;
	color: #494949;
	overflow: hidden;
}
.IntroductionSlide .TextContainer {
	width: 550px;
	margin-right: 50px;
}
.IntroductionSlide .TextContainer .H3 {
	font-size: 27px;
	margin: 0px;
	margin-bottom: 22px;
}
.IntroductionSlide .TextContainer .H1 {
	font-weight: bold;
	font-size: 60px;
	margin: 0px;
	margin-bottom: 46px;
}
.IntroductionSlide .TextContainer .H2 {
	font-size: 30px;
	color: #343434;
	margin: 0px;
}
.IntroductionSlide .TextContainer .H2 span {
	font-weight: bolder;
}

.IntroductionSlide .ImageCircleContainer {
	width: 575px;
	height: auto;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin-top: 170px;
}
.IntroductionSlide .ImageCircleContainer .Content {
	position: absolute;
	width: 550px;
}
/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1440px) {
	.IntroductionSlide .TextContainer {
		width: calc(130px + (550 - 130) * ((100vw - 375px) / (1440 - 375)));
		margin-right: calc(3px + (50 - 3) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .TextContainer .H3 {
		font-size: calc(10px + (27 - 10) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(5px + (22 - 5) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .TextContainer .H1 {
		font-size: calc(15px + (66 - 15) * ((100vw - 375px) / (1440 - 375)));
		margin-bottom: calc(8px + (46 - 8) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .TextContainer .H2 {
		font-size: calc(8px + (30 - 8) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .ImageCircleContainer {
		width:  calc(135px + (575 - 135) * ((100vw - 375px) / (1440 - 375)));
		margin-top: calc(35px + (170 - 35) * ((100vw - 375px) / (1440 - 375)));
	}

	.IntroductionSlide .ImageCircleContainer .Content {
		width: calc(135px + (550 - 135) * ((100vw - 375px) / (1440 - 375)));
	}
}
.PromotionalSlide_PromotionalSlide__3GcrI * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: rgb(255, 255, 255);
}

.PromotionalSlide_PromotionalSlide__3GcrI {
	display: grid;
	grid-template-columns: 1fr auto 281px -webkit-max-content 281px auto 1fr;
	grid-template-columns: 1fr auto 281px max-content 281px auto 1fr;
	justify-items: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(47, 68, 84, 0.522), rgba(47, 68, 84, 0.522)), url(/static/media/SlideTwoBackground.f8bff660.jpg);
	background-size: cover;
	background-position: center;
	overflow: hidden;
}

.PromotionalSlide_Message__23AF4 {
	align-self: flex-end;
	grid-column: 2/7;
	font-weight: 900;
	font-size: 42px;
	text-align: center;
	color: rgba(255, 255, 255, 0.902);
	width: 1180px;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.051);
}

.PromotionalSlide_DateTime__3MGdJ {
	justify-self: flex-end;
	grid-column: 3;
	font-weight: bold;
	font-size: 18px;
}

.PromotionalSlide_Location__3Vhmg {
	justify-self: flex-start;
	grid-column: 5;
	font-weight: bold;
	font-size: 18px;
}

.PromotionalSlide_SubMessage__1oMbq {
	grid-column: 4;
	font-weight: 400;
	font-size: 30px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.PromotionalSlide_Register__7E6Tm {
	align-self: flex-start;
	grid-column: 4;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background: rgb(96, 146, 184);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 50px;
	border: none;
	outline: none;
	font-size: 18px;
	font-weight: 500;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.251);
	padding: 5px 10px;
}

.PromotionalSlide_Register__7E6Tm:active {
	background: rgb(116, 155, 185);
}

.PromotionalSlide_Register__7E6Tm img {
	height: 100%;
	width: 47px;
	border-right: 1.5px solid rgba(0, 0, 0, 0.102);
	margin-right: 7px;
	padding-right: 7px;
}

/* Medium devices (landscape tablets, 768px and up) */

/* calc(150px + (500 - 150) * ((100vw - 375px) / (1260 - 375))); */

@media only screen and (max-width: 1260px) {
	.PromotionalSlide_PromotionalSlide__3GcrI {
		grid-template-columns: 1fr auto calc(140px + (281 - 140) * ((100vw - 375px) / (1260 - 375))) auto calc(140px + (281 - 140) * ((100vw - 375px) / (1260 - 375))) auto 1fr;
	}
	.PromotionalSlide_Message__23AF4 {
		width: calc(280px + (1180 - 280) * ((100vw - 375px) / (1260 - 375)));
		font-size: calc(10px + (37 - 10) * ((100vw - 375px) / (1260 - 375)));
	}
	.PromotionalSlide_SubMessage__1oMbq {
		font-size: calc(10px + (30 - 10) * ((100vw - 375px) / (1260 - 375)));
	}
	.PromotionalSlide_DateTime__3MGdJ {
		font-size: calc(7px + (18 - 7) * ((100vw - 375px) / (1260 - 375)));
	}
	.PromotionalSlide_Location__3Vhmg {
		font-size: calc(7px + (18 - 7) * ((100vw - 375px) / (1260 - 375)));
	}
	.PromotionalSlide_Register__7E6Tm {
		font-size: calc(5px + (15 - 5) * ((100vw - 375px) / (1260 - 375)));
		height: calc(16px + (51 - 16) * ((100vw - 375px) / (1260 - 375)));
		padding: 0px calc(5px + (10 - 5) * ((100vw - 375px) / (1260 - 375)));
	}
	.PromotionalSlide_Register__7E6Tm img {
		width: calc(20px + (47 - 20) * ((100vw - 375px) / (1260 - 375)));
		margin-right: calc(3px + (7 - 3) * ((100vw - 375px) / (1260 - 375)));
		padding-right: calc(3px + (7 - 3) * ((100vw - 375px) / (1260 - 375)));
	}
}
.VideoTestimonial_videoTestimonial__1XrXC * {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}
.VideoTestimonial_videoTestimonial__1XrXC {
	width: 330px;
	text-align: center;
	background: url(/static/media/botbackground.4338871a.png), #f2f2f2;
	background-size: cover;
	box-shadow: 0px 2.14819px 34.3711px rgba(0, 0, 0, 0.6);
	opacity: 0;
}
.VideoTestimonial_info__eEACK{
	width: 100%;
	height: 104px;
	display: grid;
    justify-items: center;
    -webkit-align-items: start;
            align-items: start;
}
.VideoTestimonial_profilePic__G_GVi {
	width: 75px;
	height: 75px;
	border-radius: 100%;
	border: 4.67809px solid #ffffff;
	object-fit: cover;
    top: -50%;
    margin-bottom: -20px;
    position: relative;
}

.VideoTestimonial_name__UE2R1 {
	font-family: "Roboto", sans-serif;
	font-size: 13px;
	font-weight: bold;
	margin-bottom: 5px;
}

.VideoTestimonial_position__13LJY {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	font-weight: bold;
}

.VideoTestimonial_triangle__16bbF{
    border-color: #f2f2f2 transparent transparent transparent;
    border-style: solid;
    border-width: 20px 15px 0 15px;
	margin-top: 8px;
	z-index: 1;
}

/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1440px) {
	.VideoTestimonial_videoTestimonial__1XrXC {
		width: calc(188px + (330 - 188) * ((100vw - 897px) / (1440 - 897)));
	}
	.VideoTestimonial_info__eEACK{
		height: calc(80px + (104 - 80) * ((100vw - 897px) / (1440 - 897)));
	}
	.VideoTestimonial_profilePic__G_GVi {
		width: calc(55px + (75 - 55) * ((100vw - 897px) / (1440 - 897)));
		height: calc(55px + (75 - 55) * ((100vw - 897px) / (1440 - 897)));
		margin-bottom: calc(-15px + (-20 - (-15)) * ((100vw - 897px) / (1440 - 897)));
	}

	.VideoTestimonial_name__UE2R1 {
		font-size: calc(10px + (13 - 10) * ((100vw - 897px) / (1440 - 897)));
		margin-bottom: calc(3px + (5 - 3) * ((100vw - 897px) / (1440 - 897)));
	}

	.VideoTestimonial_position__13LJY {
		font-size: calc(10px + (16 - 10) * ((100vw - 897px) / (1440 - 897)));
	}
	
	.VideoTestimonial_triangle__16bbF{
		margin-top: calc(5px + (8 - 5) * ((100vw - 897px) / (1440 - 897)));
	}
}

@media only screen and (max-width: 896px) {
	.VideoTestimonial_videoTestimonial__1XrXC {
		width: calc(188px + (430 - 188) * ((100vw - 375px) / (896 - 375)));
	}	
	.VideoTestimonial_info__eEACK{
		height: calc(75px + (104 - 75) * ((100vw - 375px) / (896 - 375)));
	}
	.VideoTestimonial_profilePic__G_GVi {
		width: calc(55px + (75 - 55) * ((100vw - 375px) / (896 - 375)));
		height: calc(55px + (75 - 55) * ((100vw - 375px) / (896 - 375)));
		margin-bottom: calc(-15px + (-20 - (-15)) * ((100vw - 375px) / (896 - 375)));
	}

	.VideoTestimonial_name__UE2R1 {
		font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (896 - 375)));
		margin-bottom: calc(3px + (5 - 3) * ((100vw - 375px) / (896 - 375)));
	}

	.VideoTestimonial_position__13LJY {
		font-size: calc(10px + (16 - 10) * ((100vw - 375px) / (896 - 375)));
	}
	
	.VideoTestimonial_triangle__16bbF{
		margin-top: calc(5px + (8 - 5) * ((100vw - 375px) / (896 - 375)));
	}
}
.Video_container__SuNqk{
	display: grid;
	grid-template-columns: 100%;
	justify-items: center;
	-webkit-align-items: center;
	        align-items: center;
}

.Video_video__VmEFX {
	width: 100%;
	outline: none;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    grid-column: 1;
	grid-row: 1;
}

.Video_video_grayscale__2K70f{
	border-radius: 20px;
    -webkit-filter: blur(0);
            filter: blur(0);
}

.Video_video_radius__28Avs{
	border-radius: 20px;
}


.Video_video_grayscale__2K70f::-webkit-media-controls-fullscreen-button
{
        display: none !important;
}

.Video_playButton__1Dj54 {
	-webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7));
	        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7));
	background: transparent;
	border: none;
	outline: none;
    width: 15%;
    grid-column: 1;
	grid-row: 1;
}

.Video_modalOpen__2DZ3Y{
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,.57);
}

.Video_modalClose__2LKfz{
	display: none;
	visibility: hidden;
	width: 0vw;
	height: 0vh;
}


.TextTestimonial_Container__3E-ZM * {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto";
  font-style: normal;
  box-sizing: border-box;
}
.TextTestimonial_Container__3E-ZM {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  position: relative;
  display: grid;
  grid-template-columns: auto auto 177px;
  grid-column-gap: 10px;
  padding: 24px 52px 34px 24px;
  box-sizing: border-box;
}
.TextTestimonial_QuotationMarks__2sSuc {
  width: 30px;
}
.TextTestimonial_info__72Kly {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding-top: 20px;
}
.TextTestimonial_img__3mgyw {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  object-fit: cover;
}

.TextTestimonial_position__NyuoI {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-top: 5px;
  font-size: 16px;
}
.TextTestimonial_name__QBDIn {
  font-size: 13px;
  margin-top: 4px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.TextTestimonial_Quote__1VwgU {
  text-align: center;
  font-size: 16px;
  padding-right: 40px;
  padding-top: 23px;
  text-align: justify;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

@media only screen and (max-width: 1024px) {
  .TextTestimonial_TextTestimonialCarousel__3hoIv {
    width: calc(600px + (1000 - 600) * ((100vw - 560px) / (1024 - 560)));
    height: calc(240px + (250 - 240) * ((100vw - 560px) / (1024 - 560)));
  }
  .TextTestimonial_img__3mgyw {
    width: calc(100px + (120 - 100) * ((100vw - 560px) / (1024 - 560)));
    height: calc(100px + (120 - 100) * ((100vw - 560px) / (1024 - 560)));
  }
  .TextTestimonial_position__NyuoI {
    font-size: calc(15px + (16 - 15) * ((100vw - 560px) / (1024 - 560)));
  }
  .TextTestimonial_name__QBDIn {
    margin-top: calc(3px + (4 - 3) * ((100vw - 560px) / (1024 - 560)));
  }
  .TextTestimonial_Quote__1VwgU {
    font-size: calc(15px + (16 - 15) * ((100vw - 375px) / (1024 - 375)));
    padding-right: calc(10px + (40 - 10) * ((100vw - 560px) / (1024 - 560)));
    padding-top: calc(10px + (20 - 10) * ((100vw - 560px) / (1024 - 560)));
  }
}

@media only screen and (max-width: 767px) {
  .TextTestimonial_Container__3E-ZM {
    width: 100%;
    height: 100%;
    justify-items: center;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-row-gap: 20px;
    padding: 0px 14px 24px 14px;
  }
  .TextTestimonial_info__72Kly {
    grid-column: 1/3;
    grid-row: 1;
    padding-top: 0px;
    margin-top: -50px;
  }

  .TextTestimonial_img__3mgyw {
    width: 100px;
    height: 100px;
  }

  .TextTestimonial_name__QBDIn {
    font-size: 13px;
    margin-top: 4px;
  }

  .TextTestimonial_QuotationMarks__2sSuc {
    grid-row: 2;
    margin-top: -23px;
    grid-column: 1/3;
    justify-self: flex-start;
  }

  .TextTestimonial_Quote__1VwgU {
    grid-row: 2;
    padding: 0px 20px;
    padding-bottom: 20px;
    padding-top: 0px;
    grid-column: 2/3;
  }
}

.VideoTestimonialCarousel_videoCarousel__30zKH{
	margin: auto;
	width: 100vw;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
    margin: 100px 0px 60px 0px;
}

.VideoTestimonialCarousel_container__kP2_h{
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 25px;
    grid-auto-flow: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.VideoTestimonialCarousel_button__UAr_z{
    display: none;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background: rgba(101, 110, 81, 0);
    border: none;
    z-index: 3;
    position: relative;
}

.VideoTestimonialCarousel_button__UAr_z:first-of-type{
    grid-column: 1;
    grid-row: 1;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    justify-self: end;
}
.VideoTestimonialCarousel_button__UAr_z:last-child{
    grid-column: 2;
    grid-row: 1;
    justify-self: start;
}

.VideoTestimonialCarousel_button__UAr_z img{
    width: 50px;
}

.VideoTestimonialCarousel_button__UAr_z img{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

@media only screen and (max-width: 896px) {
    .VideoTestimonialCarousel_videoCarousel__30zKH{
        display: grid;
        grid-gap: calc(250px + (450 - 250) * ((100vw - 375px) / (896 - 375)));
        -webkit-align-items: center;
                align-items: center;
    }
    .VideoTestimonialCarousel_container__kP2_h{
        height: calc(145px + (300 - 145) * ((100vw - 375px) / (896 - 375)));
        display: block;
        position: relative;
        grid-column: 1 / 3;
        grid-row: 1;
    }
    .VideoTestimonialCarousel_card__3oiy9{
        position: absolute;
        z-index: 0;
        transition: all 600ms cubic-bezier(0.5, -0.06, 0, 1);
        top: 50%;
        left: 50%;
        -webkit-transform: scale(0.5) translate(-100%, -100%);
                transform: scale(0.5) translate(-100%, -100%);
        -webkit-filter: blur(1px);
                filter: blur(1px);
    }
    .VideoTestimonialCarousel_right__V-Y98{
        left: calc(50% + (188px + (330 - 188) * ((100vw - 375px) / (896 - 375)))/2);
        -webkit-transform: translate(-50%, -50%) scale(0.8);
                transform: translate(-50%, -50%) scale(0.8);
        z-index: 1;
    }
    .VideoTestimonialCarousel_center__18WoD{
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        z-index: 2;
        -webkit-filter: blur(0);
                filter: blur(0);
    }
    .VideoTestimonialCarousel_left__3vkJY{
        left: calc(50% - (188px + (330 - 188) * ((100vw - 375px) / (896 - 375)))/2);
        -webkit-transform: translate(-50%, -50%) scale(0.8);
                transform: translate(-50%, -50%) scale(0.8);
        z-index: 1;
    }
    .VideoTestimonialCarousel_button__UAr_z{
        display: grid;
    }
    .VideoTestimonialCarousel_button__UAr_z img{
        width: calc(30px + (50 - 30) * ((100vw - 375px) / (896 - 375)));
    }

}
.TestimonialContainter_pageTesContainer__2vBUy {
	position: relative;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	background-color: #2f4454;
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	width: 100vw;
    margin: auto;
}

.TestimonialContainter_title__jZ3lw {
	font-family: "Roboto", sans-serif;
	text-align: left;
	font-weight: bold;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: #444444;
	height: 120px;
	font-size: 30px;
	width: 100%;
	background: #ffffff;
	letter-spacing: 0.1em;
}

.TestimonialContainter_TextCarousel__193Uh {
	width: 1000px;
	height: 250px;
	background-color: #f5f5f500;
	position: relative;
	overflow: hidden;
	margin: 0px 0px 60px 0px;
}

@media only screen and (max-width: 1024px) {
	.TestimonialContainter_TextCarousel__193Uh{
		width: calc(700px + (1000 - 700) * ((100vw - 768px) / (1024 - 768)));
		height: calc(300px + (250 - 300) * ((100vw - 768px) / (1024 - 768)));
	}
}

@media only screen and (max-width: 767px) {
	.TestimonialContainter_TextCarousel__193Uh{
		width: calc(350px + (700 - 350) * ((100vw - 375px) / (767 - 375)));
		height: calc(510px + (370 - 510) * ((100vw - 375px) / (767 - 375)));
	}
	.TestimonialContainter_TextCarousel__193Uh > div:first-child{
		padding-top: 50px;
	}
}
.CoursePage_coursePage__mZJRb *{
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.CoursePage_coursePage__mZJRb{
    display: grid;
    justify-items: center;
    -webkit-align-items: start;
            align-items: start;
    grid-column-gap: 1px;
}

.CoursePage_chairImg__3ZAHz{
  width: 100%;
  grid-column: 1/3;
  grid-row: 1;
}
@media only screen and (max-width: 600px) {
  .CoursePage_chairImg__3ZAHz{
    grid-column: 1;
    grid-row: 1;
    height: 85px;
  }
}
.Course_course__G8Md9 *{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  color: #444;
}
.Course_course__G8Md9{
    grid-column: 1;
    grid-row: 2;
    justify-self: end;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin: 100px 0px;
    padding: 0px 20px 20px 20px;
    display: grid;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    grid-row-gap: 35px;
    grid-column-gap: 34px;
    background: #fbfcfd;
    box-shadow: 0px 2.14819px 20px rgba(0, 0, 0, 0.12);
    overflow: hidden;
}
.Course_title__lDXCo{
    background-color: #2f4454;
    width: 200%;
    height: 80px;
    font-size: 26px;
    font-weight: 700;
    color: #ffff;
    grid-column: 1/4;
    grid-row: 1;
    display: grid;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
}

.Course_subTitle__3ju1L{
    font-size: 20px;
    font-weight: 400;
}

.Course_text__NINrT{
    width: 435px;
    height: 297px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    -webkit-align-self: start;
            align-self: start;
    overflow-y: scroll;
}

.Course_devider__2ApNQ{
    width: 1px;
    height: 100%;
    background: #2f4454;
    grid-column: 2;
    grid-row: 2/4;
}
.Course_button__1v-p1{
    font-size: 13px;
    width: 160px;
    height: 40px;
    color: #fff;
    background-color: #2f4454;
    border: none;
    text-decoration: none;
    display: grid;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.Course_downloadeButton__gmxFo{
    justify-self: end;
    grid-column: 1;
    grid-row: 4;
}
.Course_viewEventsButton__1SmkU{
    justify-self: start;
    grid-column: 3;
    grid-row: 4;
}

.Course_eachDayTitle__XaIX8{
    grid-column: 1;
    grid-row: 2;
}

.Course_eachDayText__R6tkd{
    grid-column: 1;
    grid-row: 3;
    display: grid;
    justify-items: start;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 15px;
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
}
.Course_eachDayText__R6tkd p{
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    grid-column: 2;
}
.Course_eachDayText__R6tkd img{
    grid-column: 1;
}
.Course_learnTitle__1_qv7{
    grid-column: 3;
    grid-row: 2;
}

.Course_learnText__1lJ6e{
    grid-column: 3;
    grid-row: 3;
}

/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1316px) {
    .Course_title__lDXCo{
        height: calc(70px + (80 - 70) * ((100vw - 897px) / (1316 - 897)));
        font-size: calc(20px + (26 - 20) * ((100vw - 897px) / (1316 - 897)));
    }
    .Course_text__NINrT{
        width:  calc(270px + (435 - 270) * ((100vw - 897px) / (1316 - 897)));
        height:  calc(230px + (297 - 230) * ((100vw - 897px) / (1316 - 897)));
        font-size: calc(13px + (14 - 13) * ((100vw - 897px) / (1316 - 897)));
    }
}

@media only screen and (max-width: 896px) {
    .Course_title__lDXCo{
        grid-column: 1/3;
        grid-row: 1;
    }
    .Course_text__NINrT{
        width:  calc(130px + (600 - 130) * ((100vw - 375px) / (896 - 375)));
        max-height:  calc(230px + (297 - 230) * ((100vw - 375px) / (896 - 375)));
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        font-size: calc(13px + (14 - 13) * ((100vw - 375px) / (896 - 375)));
    }
    .Course_eachDayTitle__XaIX8{
        grid-column: 1/3;
        grid-row: 2;
    }

    .Course_eachDayText__R6tkd{
        grid-column: 1/3;
        grid-row: 3;
    }
    .Course_devider__2ApNQ{
        width: 100%;
        height: 1px;
        grid-column: 1/3;
        grid-row: 4;
    }
    .Course_learnTitle__1_qv7{
        grid-column: 1/3;
        grid-row: 5;
    }
    .Course_learnText__1lJ6e{
        grid-column: 1/3;
        grid-row: 6;
    }
    .Course_downloadeButton__gmxFo{
        grid-column: 1;
        grid-row: 7;
    }
    .Course_viewEventsButton__1SmkU{
        grid-column: 2;
        grid-row: 7;
    }
}
@media only screen and (max-width: 600px) {
    .Course_course__G8Md9{
        grid-column: 1;
        grid-row: 2;
        grid-column-gap: 0px;
        margin: 0px;
        padding: 0px 20px 20px 20px;
    }
    .Course_eachDayTitle__XaIX8{
        margin-top: 60px;
    }
    .Course_text__NINrT{ 
        width: calc(100vw - 40px)
    }
    .Course_downloadeButton__gmxFo{
       justify-self: center;
    }
    .Course_viewEventsButton__1SmkU{
        justify-self: center;
    }
}

.CourseList_courseList__1HNsX *{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.CourseList_courseList__1HNsX {
	grid-column: 2;
	grid-row: 2;
    justify-self: start;
	width: 300px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin: 100px 0px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.CourseList_button__SSG9z {
	color: #ffffff;
	width: 100%;
	height: 80px;
	background-color: #2f4454;
	border: none;
	outline: none;
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-bottom: 1px solid #fbfcfd;
}

.CourseList_button__SSG9z:hover {
	background-color: #ececed;
	color: #444444;
	transition: color .4s ease, background-color .4s ease;
}
.CourseList_active__1B9X3{
	background-color: #ececed;
	color: #444444;
}

.CourseList_title__3Vlm2 {
	font-size: 17px;
	font-weight: bold;
}

.CourseList_type__KvJwe {
	font-size: 11px;
	margin-top: 5px;
}
.CourseList_open_close__1NCDF{
	display: none;
}

/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1316px) {
	.CourseList_courseList__1HNsX {
		width: calc(200px + (300 - 200) * ((100vw - 897px) / (1316 - 897)));
	}
	.CourseList_button__SSG9z {
		height: calc(60px + (80 - 60) * ((100vw - 897px) / (1316 - 897)));
	}
	.CourseList_title__3Vlm2 {
		font-size: calc(12px + (17 - 12) * ((100vw - 897px) / (1316 - 897)));
	}
	.CourseList_type__KvJwe {
		font-size: calc(10px + (11 - 10) * ((100vw - 897px) / (1316 - 897)));
	}
}
@media only screen and (max-width: 896px) {
	.CourseList_courseList__1HNsX {
		width: 200px;
	}
	.CourseList_button__SSG9z {
		height: 60px;
	}
	.CourseList_title__3Vlm2 {
		font-size: 12px;
	}

	.CourseList_type__KvJwe {
		font-size: 10px;
	}
}
@media only screen and (max-width: 600px) {
	.CourseList_courseList__1HNsX {
    	grid-column: 1;
		grid-row: 2;
		width: 100vw;
		margin: 0px;
		padding: 0px;
		margin-top: calc(71px + (81 - 71) * ((100vw - 897px) / (1316 - 897)));
		z-index: 1;
	}
	.CourseList_button__SSG9z {
		height: 80px;
		transition: all 300ms ease;
	}
	.CourseList_hide__3iU7N{
		opacity: 0;
		height: 0px;
		border-bottom: 0px solid #fbfcfd;
		visibility: hidden;
		
	}
	.CourseList_open_close__1NCDF{
		display:block;
		border-radius: 100%;
		border: none;
		outline: none;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		background: transparent;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
		margin-top: -2px;
	}
	.CourseList_open__FZZsf{
		width: 101px;
		height: 78px;
	}
	.CourseList_close__3j_D5{
		width: 68px;
		height: 71px;
	}
}

body {
  margin: 0px;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
}
.AboutPage_GridView__1s80P {
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.AboutPage_heroGridView__1HmT2 {
  margin: auto;
  max-width: 1120px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}
.AboutPage_ValuesGridView__2LcEg {
  margin: auto;
  max-width: 1480px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.AboutPage_fullWidth__9KKai {
  width: 100%;
}
.AboutPage_headerSpot__2uXa6 {
  height: 40px;
  width: 100%;
  background-color: #2f4454;
}
.AboutPage_ProfileImgContainer__1sOBZ {
  width: 100%;
  overflow: hidden;
  background-color: #fab3b2;
}
.AboutPage_ProfileImgBackG__27Lm5 {
  width: 600px;
}
.AboutPage_ProfileImg__2ULOz {
  width: 900px;
}
.AboutPage_GroupImg__368Ua {
  width: 50%;
  height: auto;
}
.AboutPage_contentHolder__2iZKS {
  width: 100%;
  height: 550px;
}
.AboutPage_missionHolder__1osDv {
  position: relative;
  width: 100%;
  height: 520px;
  display: grid;
  grid-template-areas:
    "missionTitle missionImage"
    "missionText missionImage";
  grid-template-columns: 340px 500px;
  grid-template-rows: 100px 400px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  align-self: center;
  background-color: white;
  z-index: -1;
}
.AboutPage_title__1DHos {
  font-family: "Roboto", sans-serif;
  width: 100%;
  padding: 30px 0px 30px 0px;
  font-size: 40px;
  text-align: center;
  color: #444444;
}
.AboutPage_padding__OJO4D {
  height: 500px;
  width: 100%;
  padding: 10px 18% 10px 18%;
}
.AboutPage_PicPad__3Eyno {
  width: 100%;
  padding-left: 18%;
}
.AboutPage_content__Eu5m3 {
  width: 450px;
  padding: 0px 30px 0px 30px;
  float: left;
  color: #2f4454;
  opacity: 0.8;
  line-height: 1.7;
  font-family: "Roboto", sans-serif;
  text-align: justify;
  word-spacing: -1.5px;
}
.AboutPage_centerContent__1LvHs {
  width: 15%;
  height: 250px;
  margin-top: 15px;
  padding: 3%;
  float: left;
  background-color: #efefef;
  -webkit-justify-content: center;
          justify-content: center;
}
.AboutPage_valuesContainer__10puN {
  background-color: #efefef;
  width: 100vw;
  padding-bottom: 40px;
  margin: auto;
}
.AboutPage_white__21Qxi {
  color: #ffffff;
  height: 10%;
  padding-top: 5%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.AboutPage_heroContent__34Zse {
  position: relative;
  z-index: 3;
  width: 400px;
}
.AboutPage_topRight__1h3Cq {
  width: 400px;
  font-size: 35px;
  font-family: "Roboto", sans-serif;
  opacity: 0.8;
}
.AboutPage_secondColor__3juli {
  color: #cc6566;
}
.AboutPage_secondTitle__2cRwb {
  padding: 20px 0px 20px 0px;
  font-size: 40px;
  color: #2f4454;
}
.AboutPage_centerItems__21v_X {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  margin-bottom: 20px;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.AboutPage_centerItemsValues__3HMYS {
  width: 100%;
  height: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
}
.AboutPage_missContainer__3Yu5- {
  position: absolute;
  top: -80px;
  left: -120px;
  z-index: 0;
  grid-area: missionText;
  background-color: #efefef;
  float: left;
  width: 350px;
  height: 400px;
  margin-left: 0px;
}
.AboutPage_missImgContainer__3rJYB {
  position: absolute;
  top: 0px;
  left: -220px;
  z-index: -1;
  grid-area: missionImage;
  float: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AboutPage_missionTitle__2KNfQ {
  position: absolute;
  top: 30px;
  left: -70px;
  z-index: 1;
  grid-area: missionTitle;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  color: #444444;
}
.AboutPage_missionText__2-x5Y {
  color: #2f4454;
  font-size: 17px;
  padding: 110px 45px 0px 50px;
  opacity: 0.8;
  line-height: 30px;
}
.AboutPage_missImage__2Cxmr {
  width: 860px;
}
.AboutPage_Discrimination__6N4yJ {
  margin: auto;
  max-width: 100vw;
  margin-bottom: 50px;
  text-align: justify;
  font-size: 20px;
  color: #2f4454;
  line-height: 1.4;
  font-family: "Roboto";
}
.AboutPage_Discrimination__6N4yJ p {
  padding-left: 100px;
  padding-right: 100px;
  color: #444444;
  font-weight: bold;
}
.AboutPage_DiscriminationContent__1V0pw {
  font-size: 13px;
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 1200px) {
  .AboutPage_ProfileImgContainer__1sOBZ {
    width: 100vw;
    margin: auto;
  }
  .AboutPage_heroGridView__1HmT2 {
    position: relative;
    max-width: 100vw;
    height: calc(300px + (600 - 300) * ((100vw - 600px) / (1200 - 600)));
  }
  .AboutPage_ProfileImgBackG__27Lm5 {
    position: absolute;
    width: 60vw;
    bottom: -50px;
    left: -20px;
  }
  .AboutPage_topRight__1h3Cq {
    position: absolute;
    top: calc(30px + (55 - 45) * ((100vw - 414px) / (600 - 414)));
    right: 10px;
    font-weight: 500;
    font-size: calc(10px + (20 - 13) * ((100vw - 414px) / (600 - 414)));
    width: calc(175px + (265 - 175) * ((100vw - 414px) / (600 - 414)));
    line-height: calc(18px + (23 - 15) * ((100vw - 414px) / (600 - 414)));
    font-family: "Roboto", sans-serif;
    opacity: 0.8;
  }
  .AboutPage_GridView__1s80P {
    max-width: 100vw;
  }
  .AboutPage_contentHolder__2iZKS {
    height: auto;
  }
  .AboutPage_content__Eu5m3 {
    width: 40%;
  }
  .AboutPage_missionHolder__1osDv {
    position: relative;
    top: 0px;
    left: 0px;
    display: grid;
    grid-template-areas:
      "missionTitle"
      "missionImage"
      "missionText";
    grid-template-columns: 100vw;
    grid-template-rows: 100px 450px 150px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    height: auto;
  }
  .AboutPage_missImgContainer__3rJYB {
    position: relative;
    top: -35px;
    left: -100px;
    grid-area: missionImage;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 0px;
  }
  .AboutPage_missContainer__3Yu5- {
    position: relative;
    top: 0px;
    left: 0px;
    grid-area: missionText;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 0px;
    background-color: white;
  }
  .AboutPage_missionTextBg__2YZzr {
    position: relative;
    margin: 0px;
    background-color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
  }
  .AboutPage_missionTitle__2KNfQ {
    width: 100vw;
    position: relative;
    top: 0px;
    left: 0px;
    grid-area: missionTitle;
    font-size: calc(26 + (60 - 26) * ((100vw - 600px) / (1200 - 600)));
    background-color: white;
  }
  .AboutPage_missionTitle__2KNfQ p {
    padding-top: 0px;
    padding-bottom: 20px;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }
  .AboutPage_missionText__2-x5Y {
    font-size: 17px;
    padding: 20px 45px 0px 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
  }
  .AboutPage_missImage__2Cxmr {
    width: 140vw;
  }
  .AboutPage_DiscriminationContent__1V0pw {
    font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (1200 - 375)));
  }
}
@media (max-width: 600px) {
  .AboutPage_heroGridView__1HmT2 {
    height: calc(240px + (380 - 240) * ((100vw - 414px) / (600 - 414)));
  }
  .AboutPage_ProfileImgBackG__27Lm5 {
    width: 75vw;
  }
  .AboutPage_GridView__1s80P {
    margin: auto;
    max-width: 100vw;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }
  .AboutPage_contentHolder__2iZKS {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 580px;
  }
  .AboutPage_title__1DHos {
    padding: 0px;
    font-size: 26px;
  }
  .AboutPage_centerItems__21v_X {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    display: block;
    padding: 20px;
    padding-top: 0px;
  }
  .AboutPage_content__Eu5m3 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
    font-size: 16px;
  }
  .AboutPage_missionHolder__1osDv {
    grid-template-rows: 80px auto 70px;
    height: 480px;
  }
  .AboutPage_missImgContainer__3rJYB {
    position: relative;
    top: -20px;
    left: -80px;
  }
  .AboutPage_missContainer__3Yu5- {
    position: relative;
    top: -120px;
    background-color: white;
  }
  .AboutPage_missionTextBg__2YZzr {
    background-color: #efefef;
  }
  .AboutPage_missionTitle__2KNfQ {
    font-size: 26px;
  }
  .AboutPage_valuesContainer__10puN {
    background-color: white;
  }
  .AboutPage_ValuesGridView__2LcEg {
    max-width: 100vw;
  }
  .AboutPage_secondTitle__2cRwb {
    font-size: 26px;
  }
  .AboutPage_centerItemsValues__3HMYS {
    width: 100%;
    display: block;
  }
  .AboutPage_Discrimination__6N4yJ {
    width: 100vw;
    text-align: center;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    font-size: 18px;
    padding-top: 20px;
    margin-bottom: 0px;
    background-color: #efefef;
  }
  .AboutPage_Discrimination__6N4yJ p {
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .AboutPage_DiscriminationContent__1V0pw {
    margin: 14px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
    margin-bottom: 0px;
  }
}

.Values_Values__2u5DQ {
	font-size: 15px;
	padding: 0% 1% 0% 1%;
	height: 100%;
	width: 18%;
	text-align: center;
}
.Values_ValueImg__3sHkU {
	width: 55%;
	height: auto;
}
.Values_white__1ndbC {
	color: #2F4454;
	height: 10%;
	padding-top: 5%;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-bottom: 10px;
	font-size: 25px;
	font-weight: bolder;
}
.Values_LineDivider__1oC3F {
	width: 99%;
	height: 9px;
	border-style: solid;
	border-width: 2px 0px 0px 0px;
	border-color: #2F4454;
}
.Values_valueDescription__1GcFq {
	text-align: center;
	color: #2F4454;
}

@media (max-width:600px){

	.Values_Values__2u5DQ:nth-of-type(even){
		grid-template-areas: "valuesText valuesImage" 
							"valuesText valuesTitle";	
		grid-template-columns: 200px 160px;
	}
	.Values_Values__2u5DQ {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		text-align: center;
		display: grid;
		grid-template-areas: "valuesImage valuesText" 
							"valuesTitle valuesText";
		grid-template-columns: 160px 200px;
		grid-column-gap: 11px;
		margin: auto;
		margin-bottom: 41px;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-content: center;
		        align-content: center;
		text-align: center;	
	}
	.Values_white__1ndbC {
		grid-area: valuesTitle;
		font-weight: bold;
		line-height: 28px;
	}

	.Values_ValueImg__3sHkU {
		grid-area: valuesImage;
		width: 100%;
		height: 150px;
	}
	.Values_LineDivider__1oC3F {
		display: none;
	}
	.Values_valueDescription__1GcFq {
		grid-area: valuesText;
		margin: auto;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.05em;
	}

}
.ContactForm {
  background-color: #ffffff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  font-family: "Roboto", sans-serif;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.ContactForm .CloseButton {
  float: right;
  margin: 6px;
  background-color: transparent;
  border: none;
  padding: 0px;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}
.ContactForm .Title {
  display: block;
  width: 100% !important;
  height: auto;
  text-align: center;
  font-weight: bold;
  font-size: 40.0191px;
  color: #2f4454;
  margin-top: 20px;
  margin-bottom: 16px;
}
.ContactForm .SubTitle {
  width: 580.3px;
  height: 47.93px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18.1337px;
  text-align: center;
  color: rgba(47, 68, 84, 0.8);
  margin: auto;
}
.ContactForm form {
  text-align: center;
  margin: auto;
  margin-top: 20px;
  width: 1000px;
}
.ContactForm form .Container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 778.05px;
  margin: auto;
}
.ContactForm .Container .Input {
  width: 277.05px;
  height: 70px;
}
.ContactForm .Container .Textarea {
  width: 500px;
  height: 150px;
  margin-bottom: 70px;
  margin-top: 10px;
}
.ContactForm .FromSubmit {
  width: 200px;
  height: 40.36px;
  background-color: rgb(47, 68, 84);
  font-weight: 600;
  font-size: 18.1337px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
}
.ContactForm .MessageContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0px 20px 0px 20px;
}
.ContactForm .MessageContainer .message {
  font-weight: 100;
  font-size: 14.1337px;
  color: rgba(47, 68, 84, 0.7);
  text-align: right;
}
@media (max-width: 600px) {
  .ContactForm {
    width: 100vw;
    height: 470px;
  }
  .ContactForm .Title {
    font-size: 20px;
  }
  .ContactForm .SubTitle {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05em;
    padding-left: 15px;
    padding-right: 15px;
  }
  .ContactForm form {
    margin-top: 0px;
    width: 100vw;
  }
  .ContactForm form .Container {
    width: 100vw;
  }
  .ContactForm .Container .Textarea {
    width: 300px;
    height: 90px;
    margin-bottom: 15px;
    margin-top: 0px;
  }
  .ContactForm .Container .Input {
    width: 150px;
    height: 70px;
  }
  .ContactForm .FromSubmit {
    width: 50%;
    font-size: 17px;
    height: 35px;
    border: none;
  }
  .ContactForm .MessageContainer {
    display: block;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0px 20px 0px 20px;
  }
  .ContactForm .MessageContainer .message {
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
}

body{
   margin: 0;
}

.Hero_GridView__2lMJv {
	margin: auto;
    max-width: 1080px; 
    position: relative;

}

.Hero_mainContainer__1liwc{
    position: relative;
    width:100%; 
    height:550px;
    background-color: #2F4454;
    margin:auto;
}

  .Hero_textContainer__2PE2N{
    width: 600px;
    height:500px;
    float: left;
}
.Hero_headerTitle__o2PD3{ 
    font-family: "Roboto";
    width: 150px;
    font-size: 40px; 
    padding: 200px 0px 0px 40px;  
}
.Hero_headerCont__3crGu{
    font-family: "Roboto";
    color:#FFFFFF;
}
.Hero_headerText__3wma5{
    width: 550px;
    font-size: 35px; 
    padding: 40px 0px 0px 40px ; 
    opacity: .8;
}

.Hero_headerImgCont__3PJ4l{
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100px;
    background-image: url(/static/media/confRoomTest.347b7735.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 15%;
    width: 400px;
    height: 500px;

}
.Hero_headerImgCont__3PJ4l::after{
    content: "";
    background: #2f445467;
    position: absolute;
    border-top-left-radius: 15%;
    width: 400px;
    height: 500px;
    z-index: 1;
}
@media (max-width:600px){
    .Hero_GridView__2lMJv {
       width: 100%; 
       height: 100%;
       position: relative;
    }
    .Hero_mainContainer__1liwc{
        position: relative;
        background-size: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height:290px;
        width:100vw;
        background-color: white;
    }
    
    .Hero_textContainer__2PE2N{
        position: relative;
        width: 0px;
        height:200px;
        float: left;
        z-index: 2;
    }
    .Hero_headerTitle__o2PD3{ 
        font-size: 26px; 
        padding: 150px 0px 0px 60px;  
    }
    .Hero_headerText__3wma5{
        width: 300px;
        font-size: 18px; 
        padding: 15px 0px 0px 60px ; 
    }

    .Hero_headerImgCont__3PJ4l {
        position: relative; 
        width: inherit;
        height: inherit;
        padding-top:0px;
        padding-left:0px;
    }
   
    .Hero_headerImgCont__3PJ4l{
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0px;
        background-image: url(/static/media/confRoomTest.347b7735.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100vw;
        border-top-left-radius: 0%;
    
    }
    .Hero_headerImgCont__3PJ4l::after{
        content: "";
        background: #2f445467;
        position: absolute;
        width: 100vw;
        z-index: 1;
        border-top-left-radius: 0%;
    }
 

}
.onLocation_GridView__2Jcj6 {
	margin: auto auto;
	max-width: 1080px;
}
.onLocation_mainContainer__2lkjx {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin-top: 100px;
	margin-bottom: 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.onLocation_ContactUsContainer__GiE3P{
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	margin-top: 100px;
	margin-bottom: 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.onLocation_trainingContainer__1WG-n {
	display: grid;
	grid-template-columns: 500px auto;
	margin:auto;
	margin-top: 100px;
}
.onLocation_infoTitle__1YksX {
	justify-self: start;
	font-family: "Roboto",sans-serif;
	font-weight: bold;
	color: #333333;
	font-size: 35px;
	line-height: 50px;
	grid-column: 2/3;
	margin-left: 60px;
}

.onLocation_textImgWrap__5uvq_{
	grid-column: 1/3;
}
.onLocation_clsRoomImg__hrE-s {
	width: 500px;
	height: auto;
	float: left;
	margin-top: -90px;
	margin-right: 60px;
}
.onLocation_content__SYvE_ {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 80%;
	grid-gap: 10px 0px;
	font-family: "Roboto",sans-serif;
	color: #666666;
	font-size: 18px;
	font-weight: bold;
	line-height: 30px;
}
.onLocation_contContactUS__3AhPf {
	position: relative;
	width: 550px;
}
.onLocation_contContactUSText__lJJJf {
	position: relative;
	width: 550px;
}
.onLocation_topicContent__3VP4V {
	width: 50%;
	float: left;
	font-family: "Roboto",sans-serif;
	margin-right: 70px;
}
.onLocation_contactUsText__9MdiB {
	font-family: "Roboto",sans-serif;
	font-weight: bold;
	width: 500px;
	font-size: 30px;
	line-height: 1.6;
}
.onLocation_topicOptions__1wUkH {
	font-family: "Roboto",sans-serif;
	width: 100%;
	font-weight: bold;
	opacity: .9;
}
.onLocation_teachingPic__15pc0 {
	width: 600px;
	height: auto;
	-webkit-clip-path: inset(0 0 20% 0);
	        clip-path: inset(0 0 20% 0);
}
.onLocation_highlightText__1sG8C {
	color: #6092b8;
	font-weight: bold;
}
.onLocation_contactBtn__26wQ1 {
	color: #ffffff;
	font-size: 18px;
	background-color: #6092b8;
	border: none;
	height: 50px;
	width: 250px;
	border-radius: 25px;
	margin-top: 30px;
	outline: none;
}

.onLocation_contactBtn__26wQ1:focus {
	box-shadow: 0 0 2pt 2pt #a9daff;
}

.onLocation_individualTopic__1iEi3 {
	padding: 5px 0px 10px 0;
	
	width: -webkit-max-content;
	
	width: -moz-max-content;
	
	width: max-content;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	font-family: "Roboto",sans-serif;
	font-size: medium;
	opacity: .9;
	color: #000000;
	display: -webkit-flex;
	display: flex;
}
.onLocation_botTopPadding__3vpa7 {
	padding-bottom: 60px;
	padding-top: 100px;
}
.onLocation_topicTriangle__2N4z3 {
	width: 13px;
	height: auto;
	float: left;
	padding-right: 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-content: center;
	        align-content: center;
	opacity: 1;
}

.onLocation_circlePaperImg__14u6d {
	width: 230px;
	margin-top: 120px;
	margin-left: 80px;	
}
.onLocation_topicTitle__A2P-_ {
	width: 600px;
	font-weight: bold;
	color: #333333;
	font-size: 35px;
	font-family: "Roboto",sans-serif;
	
}
@media (max-width:600px){

	.onLocation_GridView__2Jcj6 {
		margin:auto;
		width: 100vw; 
	 }
	 .onLocation_trainingContainer__1WG-n {
		grid-template-columns:auto;
		margin-top: 20px;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-content: center;
		        align-content: center;	
	}
	.onLocation_infoTitle__1YksX {
		grid-column: 1/2;
		justify-self: center;
		font-size: 22px;
		margin-left: 0px;
		text-align: center;
		width: 395px;
		margin-bottom: 10px;
	}
	.onLocation_textImgWrap__5uvq_{
		grid-column: 1/2;
		margin-left: 20px;
		margin-right: 20px;
	}
	.onLocation_clsRoomImg__hrE-s {
		width: 52vw;
		margin-top: 0px;
		margin: 15px;
	}
	.onLocation_content__SYvE_ {
	display: block;
	}

	.onLocation_mainContainer__2lkjx {
		width:100vw;
		position: relative;
		margin-top: 20px;
		margin-bottom: 50px;
	}
	.onLocation_ContactUsContainer__GiE3P{
		display: grid;
		grid-template-areas: "contactImage" 
							"contactText";
		width: 100vw;
		padding-bottom: 0px;
		margin-top:0px;
		margin-bottom: 0px;
		height:-webkit-max-content;
		height:-moz-max-content;
		height:max-content;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		text-align: center;
	}


	.onLocation_topicContent__3VP4V {
		width: 100vw ;
		margin-left: 10px;
		margin-right: 0px;
		
	}
	.onLocation_topicTitle__A2P-_ {
		text-align: center;
		width: 100vw;
		font-size: 22px;
		text-align: center;
		color: #666666;
	}
	.onLocation_botTopPadding__3vpa7 {
		padding-bottom: 30px;
		padding-top: 50px;
	}
	.onLocation_individualTopic__1iEi3 {
		font-size: calc(14px + (20 - 14) * ((100vw - 414px) / (600 - 414)));
	}

	.onLocation_teachingPic__15pc0 {
		width: 100wv;
	}
	.onLocation_circlePaperImg__14u6d {
		position: absolute;
		bottom:-10px;
		right:60px;
		width: 80px;
		padding: 0px;
		padding-top: 0px;
	}

	.onLocation_topicTriangle__2N4z3 {
		width: 8px;
	}

	.onLocation_contContactUS__3AhPf {
		width: 100vw;
		grid-area: contactImage;
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
	}
	.onLocation_contContactUSText__lJJJf {
		position: relative;
		top:-60px;
		grid-area: contactText;
		width: 100vw;	
	}
	
	.onLocation_contactUsText__9MdiB {
		margin:auto;
		font-size: calc(20px + (30 - 20) * ((100vw - 414px) / (600 - 414)));
		width:calc(340px + (470 - 340) * ((100vw - 414px) / (600 - 414)));
		
	}
	.onLocation_teachingPic__15pc0 {
		width: 100vw;
		height: auto;
		-webkit-clip-path: inset(0 0 20% 0);
		        clip-path: inset(0 0 20% 0);
	}
}

.Footer_Footer__1TTYM {
  font-family: "Roboto", sans-serif;
  width: 100% !important;
  background-color: #21303c;
  width: 100vw;
  height: 350px;
}
.Footer_Grid__1ZHBX {
  margin: auto auto;
  max-width: 1080px;
}
.Footer_TipOfDayContent__sfbzR {
  position: relative;
  margin: auto;
  margin-top: 30px;
  display: grid;
  grid-template-areas:
    "logo title"
    "logo title2"
    "inputForm inputForm";
  grid-template-rows: 30px 40px 40px;
  grid-template-columns: 120px 340px;
  -webkit-justify-content: center;
          justify-content: center;
  align-self: center;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: 0.05em;
  width: 400px;
}
.Footer_Logo__1Q123 {
  grid-area: logo;
  width: 80%;
}

.Footer_title__Gid2q {
  position: absolute;
  top: 0px;
  left: 32px;
  grid-area: title;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  margin: auto;
  color: #ffffff;
}
.Footer_title2__2JpDt {
  grid-area: title2;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 128.91%;
  letter-spacing: 0.05em;
  margin: auto;
  margin-top: none;
  margin-bottom: none;
  color: #ffffff;
}
.Footer_inputForm__F_m0A {
  grid-area: inputForm;
  margin-top: 21px;
  position: relative;
  width: 480px;
}
.Footer_inputf__2TLpj {
  position: relative;
  top: 0px;
  left: -20px;
  width: 75%;
  height: 35px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #21303c;
  color: #ffffff;
}
.Footer_inputButton__nYyD6 {
  position: relative;
  top: 0px;
  right: 0px;
  width: 20%;
  height: 35px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #21303c;
  color: #ffffff;
}

.Footer_moreInformation__3Agis {
  display: grid;
  grid-template-areas: "support explore connect contact";
  grid-template-columns: 100px 100px 100px auto;
  grid-column-gap: 50px;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  margin-left: 120px;
  color: #ffffff;
}
.Footer_moreInfoTitles__3KtpY {
  margin-top: 20px;
  height: 20px;
  font-weight: bold;
  font-size: 18px;
}
.Footer_contact__1tr5e {
  cursor: pointer;
}
.Footer_supportContainer__2thcQ {
  grid-area: support;
  text-align: center;
}
.Footer_exploreContainer__1MAoH {
  grid-area: explore;
  text-align: center;
  display: block;
}
.Footer_connectContainer__1Mpl5 {
  grid-area: connect;
  text-align: center;
}
.Footer_contactContainer__1-H79 {
  grid-area: contact;
}
.Footer_moreInformation__3Agis a {
  color: #ffffff;
  text-decoration: none;
  display: inline-table;
  margin-top: 12px;
}
.Footer_footerImg__AiTXs {
  width: 19px;
  margin-right: 10px;
}
@media (max-width: 900px) {
  .Footer_moreInformation__3Agis {
    grid-template-columns: 80px 70px 80px auto;
    grid-column-gap: 30px;
    margin-left: 20px;
  }
  .Footer_moreInformation__3Agis .Footer_moreInfoTitles__3KtpY {
    font-size: 15px;
  }
  .Footer_moreInformation__3Agis a {
    font-size: 13px;
  }
  .Footer_moreInformation__3Agis p {
    font-size: 13px;
  }
  .Footer_footerImg__AiTXs {
    width: 15px;
    margin-right: 5px;
  }
}
@media (max-width: 600px) {
  .Footer_TipOfDayContent__sfbzR {
    position: relative;
    margin-top: 20px;
    grid-template-columns: 100px 280px;
    grid-template-rows: 40px 40px 40px;
  }
  .Footer_Logo__1Q123 {
    position: relative;
    top: -15px;
    left: 0px;
    grid-area: logo;
    width: 100%;
  }
  .Footer_title__Gid2q {
    position: relative;
    top: 18px;
    left: 20px;
    font-size: 20px;
    line-height: 27px;
    margin: 0;
    margin: initial;
  }
  .Footer_title2__2JpDt {
    position: relative;
    top: 0px;
    left: -25px;
    margin: 0;
    margin: initial;
    font-size: 12px;
    line-height: 128.91%;
    padding-top: 5px;
  }
  .Footer_inputForm__F_m0A {
    margin-top: 0px;
    width: 100%;
  }
  .Footer_inputf__2TLpj {
    left: 30px;
    width: 60%;
  }
  .Footer_inputButton__nYyD6 {
    left: 40px;
    width: 22%;
  }
  .Footer_moreInformation__3Agis {
    grid-template-areas:
      "connect connect"
      "support contact";
    grid-template-columns: 90px 250px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .Footer_connectContainer__1Mpl5 {
    grid-area: connect;
    text-align: center;
    position: relative;
    left: 0px;
    font-size: 15px;
  }
  .Footer_connectContainer__1Mpl5 .Footer_moreInfoTitles__3KtpY {
    display: none;
  }
  .Footer_connectContainer__1Mpl5 a {
    margin-right: 30px;
  }
  .Footer_moreInfoTitles__3KtpY {
    font-size: 14px;
  }
  .Footer_supportContainer__2thcQ {
    grid-area: support;
    text-align: center;
    position: relative;
    left: 25px;
  }
  .Footer_supportContainer__2thcQ p {
    font-size: 14px;
  }
  .Footer_contactContainer__1-H79 {
    grid-area: contact;
    position: relative;
    left: 10px;
  }
  .Footer_contactContainer__1-H79 .Footer_moreInfoTitles__3KtpY {
    font-size: 14px;
    text-align: center;
  }
  .Footer_contactContainer__1-H79 p {
    font-size: 12px;
    margin-left: 15px;
  }
  .Footer_exploreContainer__1MAoH {
    display: none;
  }
  .Footer_moreInformation__3Agis a {
    font-size: 14px;
  }
  .Footer_footerImg__AiTXs {
    width: 15px;
    margin-right: 10px;
  }
}

.Header {
  width: 100%;
  height: 65px;
  background-color: #21303c;
  transition: top 200ms ease-in;
  top: 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: 65px;
  grid-template-areas: "logo navbar";
  justify-items: center;
}
.HeaderTopOffSet {
  top: -80px;
  transition: top 300ms ease-in;
}
.HeaderPositionFixed {
  position: fixed !important;
  z-index: 7;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.25);
}
.BodyMargin > div:nth-child(2) {
  margin-top: 70px;
}

.Header .GridView {
  margin: auto;
  max-width: 1120px;
}

.Header > a {
  grid-area: logo;
}

.Header-navBar {
  grid-area: navbar;
  display: grid;
  grid-template-columns: repeat(5, auto);
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  grid-column-gap: 33px;
}

.Header-navBar a {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  width: 100%;
}

.Header-navBar p {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
  cursor: pointer;
  width: 100%;
}

.Header-Logo {
  width: 85px;
  height: 60px;
}

.hamburger {
  grid-area: hamburger;
  display: none;
  cursor: pointer;
}

.mobile-bars {
  margin-right: 20px;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.4s;
}

@media screen and (max-width: 600px) {
  .hamburger {
    -webkit-perspective: 900000px;
    perspective: 900000px;
    display: inline-block;
    -webkit-align-self: center;
            align-self: center;
    justify-self: end;
  }
  .Header {
    display: grid;
    position: relative;
    grid-template-columns: auto auto;
    grid-template-rows: 60px auto;
    grid-template-areas:
      "logo hamburger"
      "navbar filler";
    justify-items: center;
  }
  .Header > a {
    grid-area: logo;
    justify-self: start;
    margin-left: 20px;
  }

  .Header-navBar {
    grid-area: navbar;
    position: absolute;
    background-color: #21303c;
    grid-column: 1/3;
    align-self: stretch;
    width: 100%;
    height: 100vh;
    right: -100%;
    z-index: 7;
    transition: all 300ms linear;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-auto-rows: 50px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
  }

  .open {
    right: 0;
  }
  .Header-navBar a {
    border-bottom: 1px solid rgb(249, 249, 249, 0.3);
    border-bottom: 1px solid rgba(249, 249, 249, 0.3);
    font-size: 20px;
    line-height: 50px;
    height: 100% !important;
  }
  .Header-navBar p {
    border-bottom: 1px solid rgb(249, 249, 249, 0.3);
    border-bottom: 1px solid rgba(249, 249, 249, 0.3);
    padding-bottom: 5px;
    font-size: 20px;
    line-height: 23px;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
}

.Accordion_question__3lpuO {
  margin-top: 30px;
  width: 1050px;
  height: 44px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.05em;
  text-align: left;
  cursor: pointer;

  color: #2f4454;
}

.Accordion_answer__3SD3u {
  max-height: 124px;
  transition: max-height 400ms ease-out;
}

.Accordion_answerHide__3YG1G {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 200ms ease-out;
}

.Accordion_answerText__3JN_G {
  width: 950px;
  margin: auto auto;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: justify;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.05em;
  color: rgba(47, 68, 84, 0.8);
}

.Accordion_openIcon__3hQXL {
  width: 21px;
  height: 21px;
  float: right;
  -webkit-transform: rotate(-90deg) scale(0.75);
          transform: rotate(-90deg) scale(0.75);
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}

.Accordion_closedIcon__MwcKu {
  width: 21px;
  height: 21px;
  float: right;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}

@media (max-width: 600px) {
  .Accordion_question__3lpuO {
    width: 90vw;
    margin-left: 20px;
  }
  .Accordion_answerText__3JN_G {
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
  }
}

.FAQPage_container__3KcWP {
  width: 1050px;
  margin: auto auto;
  margin-bottom: 200px;
}

.FAQPage_headingContainer__1haQ_ {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}
.FAQPage_heading__2GwAG {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.05em;

  color: #2f4454;
}

.FAQPage_helpIcon__1S7OJ {
  margin-top: 20px;
}
@media (max-width: 600px) {
  .FAQPage_container__3KcWP {
    width: 100vw;
    margin: 0px;
    margin-bottom: 40px;
  }
  .FAQPage_headingContainer__1haQ_ {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .FAQPage_heading__2GwAG {
    font-size: 14px;
  }
  .FAQPage_helpIcon__1S7OJ {
    margin-top: 0px;
    width: 36px;
  }
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.App {
  height: 100vh;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  justify-items: stretch;
}

* {
  font-family: "Roboto", sans-serif;
}

button {
	cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; /* Non-prefixed version, currently */
}

img{
  -webkit-user-drag: none;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
}

