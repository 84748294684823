.ContactForm {
  background-color: #ffffff;
  width: max-content;
  height: max-content;
  font-family: "Roboto", sans-serif;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.ContactForm .CloseButton {
  float: right;
  margin: 6px;
  background-color: transparent;
  border: none;
  padding: 0px;
  display: flex;
  cursor: pointer;
}
.ContactForm .Title {
  display: block;
  width: 100% !important;
  height: auto;
  text-align: center;
  font-weight: bold;
  font-size: 40.0191px;
  color: #2f4454;
  margin-top: 20px;
  margin-bottom: 16px;
}
.ContactForm .SubTitle {
  width: 580.3px;
  height: 47.93px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18.1337px;
  text-align: center;
  color: rgba(47, 68, 84, 0.8);
  margin: auto;
}
.ContactForm form {
  text-align: center;
  margin: auto;
  margin-top: 20px;
  width: 1000px;
}
.ContactForm form .Container {
  display: flex;
  justify-content: space-around;
  width: 778.05px;
  margin: auto;
}
.ContactForm .Container .Input {
  width: 277.05px;
  height: 70px;
}
.ContactForm .Container .Textarea {
  width: 500px;
  height: 150px;
  margin-bottom: 70px;
  margin-top: 10px;
}
.ContactForm .FromSubmit {
  width: 200px;
  height: 40.36px;
  background-color: rgb(47, 68, 84);
  font-weight: 600;
  font-size: 18.1337px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
}
.ContactForm .MessageContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
}
.ContactForm .MessageContainer .message {
  font-weight: 100;
  font-size: 14.1337px;
  color: rgba(47, 68, 84, 0.7);
  text-align: right;
}
@media (max-width: 600px) {
  .ContactForm {
    width: 100vw;
    height: 470px;
  }
  .ContactForm .Title {
    font-size: 20px;
  }
  .ContactForm .SubTitle {
    width: fit-content;
    height: auto;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05em;
    padding-left: 15px;
    padding-right: 15px;
  }
  .ContactForm form {
    margin-top: 0px;
    width: 100vw;
  }
  .ContactForm form .Container {
    width: 100vw;
  }
  .ContactForm .Container .Textarea {
    width: 300px;
    height: 90px;
    margin-bottom: 15px;
    margin-top: 0px;
  }
  .ContactForm .Container .Input {
    width: 150px;
    height: 70px;
  }
  .ContactForm .FromSubmit {
    width: 50%;
    font-size: 17px;
    height: 35px;
    border: none;
  }
  .ContactForm .MessageContainer {
    display: block;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
  }
  .ContactForm .MessageContainer .message {
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
}
