.Textarea {
	width: 100%;
	height: 100%;
	position: relative;
	font-family: 'Roboto', sans-serif;
	outline: none;
}
.Textarea .Textarea-Input {
	color: #2f4454;
	border: none;
	border-bottom: solid 2px #2f4454;
	font-size: 14px;
	font-family: sans-serif;
	outline: none;
	width: 100%;
	Height: 100%;
	position: absolute;
	bottom: 0%;
	left: 0%;
	resize: none;
}
.Textarea .Textarea-placeholder {
	pointer-events: none;
	align-items: flex-end;
	display: flex;
	justify-content: center;
	width: 100%;
	Height: 100%;
	padding-bottom: 5px;
	position: absolute;
	bottom: 0%;
	left: 0%;
}
.Textarea .Textarea-placeholder::after {
	border-bottom: solid 2px #2f4454;
	content: "";
	display: block;
	width: 100%;
	Height: 100%;
	position: absolute;
	bottom: 0%;
	left: 0%;
	transform: scaleX(0);
	transition: transform 250ms ease-in-out;
}

.Textarea .Textarea-Input:focus + .Textarea-placeholder:after,
.Textarea .Textarea-Input:not(:placeholder-shown) + .Textarea-placeholder:after {
	transform: scaleX(1);
}

.Textarea .Textarea-placeholder .Textarea-placeholder-text {
	font-size: 20px;
	color: #2f4454;
	font-family: sans-serif;
	transition: all 0.3s ease;
}

.Textarea .Textarea-Input:focus + .Textarea-placeholder .Textarea-placeholder-text,
.Textarea .Textarea-Input:not(:placeholder-shown) + .Textarea-placeholder .Textarea-placeholder-text {
	transition: opacity 250ms ease-out;
	opacity: 0;
}
@media (max-width:600px){
	.Textarea .Textarea-placeholder .Textarea-placeholder-text {
		
		color: #2f4454;
		font-family: sans-serif;
		transition: all 0.3s ease;


		font-size: 12px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.05em;
		
		color: #2F4454;
		
		opacity: 0.9;
	}
	.Textarea .Textarea-placeholder .Textarea-placeholder-text {
		
		color: #2f4454;
		font-family: sans-serif;
		transition: all 0.3s ease;

		position: absolute;
		top:40px;
		font-size: 12px;
	}
	
}