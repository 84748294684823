.videoCarousel{
	margin: auto;
	width: 100vw;
	height: fit-content;
    margin: 100px 0px 60px 0px;
}

.container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 25px;
    grid-auto-flow: column;
    justify-content: center;
}

.button{
    display: none;
    width: max-content;
    height: max-content;
    background: rgba(101, 110, 81, 0);
    border: none;
    z-index: 3;
    position: relative;
}

.button:first-of-type{
    grid-column: 1;
    grid-row: 1;
    transform: rotate(180deg);
    justify-self: end;
}
.button:last-child{
    grid-column: 2;
    grid-row: 1;
    justify-self: start;
}

.button img{
    width: 50px;
}

.button img{
    transform: rotate(180deg);
}

@media only screen and (max-width: 896px) {
    .videoCarousel{
        display: grid;
        grid-gap: calc(250px + (450 - 250) * ((100vw - 375px) / (896 - 375)));
        align-items: center;
    }
    .container{
        height: calc(145px + (300 - 145) * ((100vw - 375px) / (896 - 375)));
        display: block;
        position: relative;
        grid-column: 1 / 3;
        grid-row: 1;
    }
    .card{
        position: absolute;
        z-index: 0;
        transition: all 600ms cubic-bezier(0.5, -0.06, 0, 1);
        top: 50%;
        left: 50%;
        transform: scale(0.5) translate(-100%, -100%);
        filter: blur(1px);
    }
    .right{
        left: calc(50% + (188px + (330 - 188) * ((100vw - 375px) / (896 - 375)))/2);
        transform: translate(-50%, -50%) scale(0.8);
        z-index: 1;
    }
    .center{
        transform: translate(-50%, -50%);
        z-index: 2;
        filter: blur(0);
    }
    .left{
        left: calc(50% - (188px + (330 - 188) * ((100vw - 375px) / (896 - 375)))/2);
        transform: translate(-50%, -50%) scale(0.8);
        z-index: 1;
    }
    .button{
        display: grid;
    }
    .button img{
        width: calc(30px + (50 - 30) * ((100vw - 375px) / (896 - 375)));
    }

}