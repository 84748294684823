.Confirmation {
  width: 968px;
  height: 605.53px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Title {
  text-align: center;
  font-weight: bold;
  font-size: 40.0191px;
  color: #2f4454;
  margin-bottom: 20px;
  width: 660px;
}
.SubTitle {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 25px;
  text-align: center;
  color: rgba(47, 68, 84, 0.8);
}
@media (max-width: 600px) {
  .Confirmation {
    width: 100vw;
    height: 300.53px;
  }
  .Title {
    font-size: 25.0191px;
    width: 80vw;
  }
  .SubTitle {
    font-size: 15px;
  }
}
