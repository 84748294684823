.container{
	display: grid;
	grid-template-columns: 100%;
	justify-items: center;
	align-items: center;
}

.video {
	width: 100%;
	outline: none;
    filter: grayscale(100%);
    grid-column: 1;
	grid-row: 1;
}

.video_grayscale{
	border-radius: 20px;
    filter: blur(0);
}

.video_radius{
	border-radius: 20px;
}


.video_grayscale::-webkit-media-controls-fullscreen-button
{
        display: none !important;
}

.playButton {
	filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7));
	background: transparent;
	border: none;
	outline: none;
    width: 15%;
    grid-column: 1;
	grid-row: 1;
}

.modalOpen{
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,.57);
}

.modalClose{
	display: none;
	visibility: hidden;
	width: 0vw;
	height: 0vh;
}

