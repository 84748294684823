.course *{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  color: #444;
}
.course{
    grid-column: 1;
    grid-row: 2;
    justify-self: end;
    width: max-content;
    height: max-content;
    margin: 100px 0px;
    padding: 0px 20px 20px 20px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-row-gap: 35px;
    grid-column-gap: 34px;
    background: #fbfcfd;
    box-shadow: 0px 2.14819px 20px rgba(0, 0, 0, 0.12);
    overflow: hidden;
}
.title{
    background-color: #2f4454;
    width: 200%;
    height: 80px;
    font-size: 26px;
    font-weight: 700;
    color: #ffff;
    grid-column: 1/4;
    grid-row: 1;
    display: grid;
    justify-items: center;
    align-items: center;
}

.subTitle{
    font-size: 20px;
    font-weight: 400;
}

.text{
    width: 435px;
    height: 297px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    align-self: start;
    overflow-y: scroll;
}

.devider{
    width: 1px;
    height: 100%;
    background: #2f4454;
    grid-column: 2;
    grid-row: 2/4;
}
.button{
    font-size: 13px;
    width: 160px;
    height: 40px;
    color: #fff;
    background-color: #2f4454;
    border: none;
    text-decoration: none;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.downloadeButton{
    justify-self: end;
    grid-column: 1;
    grid-row: 4;
}
.viewEventsButton{
    justify-self: start;
    grid-column: 3;
    grid-row: 4;
}

.eachDayTitle{
    grid-column: 1;
    grid-row: 2;
}

.eachDayText{
    grid-column: 1;
    grid-row: 3;
    display: grid;
    justify-items: start;
    align-items: center;
    grid-gap: 15px;
    grid-auto-rows: min-content;
}
.eachDayText p{
    height: max-content;
    grid-column: 2;
}
.eachDayText img{
    grid-column: 1;
}
.learnTitle{
    grid-column: 3;
    grid-row: 2;
}

.learnText{
    grid-column: 3;
    grid-row: 3;
}

/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1316px) {
    .title{
        height: calc(70px + (80 - 70) * ((100vw - 897px) / (1316 - 897)));
        font-size: calc(20px + (26 - 20) * ((100vw - 897px) / (1316 - 897)));
    }
    .text{
        width:  calc(270px + (435 - 270) * ((100vw - 897px) / (1316 - 897)));
        height:  calc(230px + (297 - 230) * ((100vw - 897px) / (1316 - 897)));
        font-size: calc(13px + (14 - 13) * ((100vw - 897px) / (1316 - 897)));
    }
}

@media only screen and (max-width: 896px) {
    .title{
        grid-column: 1/3;
        grid-row: 1;
    }
    .text{
        width:  calc(130px + (600 - 130) * ((100vw - 375px) / (896 - 375)));
        max-height:  calc(230px + (297 - 230) * ((100vw - 375px) / (896 - 375)));
        height: max-content;
        font-size: calc(13px + (14 - 13) * ((100vw - 375px) / (896 - 375)));
    }
    .eachDayTitle{
        grid-column: 1/3;
        grid-row: 2;
    }

    .eachDayText{
        grid-column: 1/3;
        grid-row: 3;
    }
    .devider{
        width: 100%;
        height: 1px;
        grid-column: 1/3;
        grid-row: 4;
    }
    .learnTitle{
        grid-column: 1/3;
        grid-row: 5;
    }
    .learnText{
        grid-column: 1/3;
        grid-row: 6;
    }
    .downloadeButton{
        grid-column: 1;
        grid-row: 7;
    }
    .viewEventsButton{
        grid-column: 2;
        grid-row: 7;
    }
}
@media only screen and (max-width: 600px) {
    .course{
        grid-column: 1;
        grid-row: 2;
        grid-column-gap: 0px;
        margin: 0px;
        padding: 0px 20px 20px 20px;
    }
    .eachDayTitle{
        margin-top: 60px;
    }
    .text{ 
        width: calc(100vw - 40px)
    }
    .downloadeButton{
       justify-self: center;
    }
    .viewEventsButton{
        justify-self: center;
    }
}
