.Container {
	width: max-content;
	height: max-content;
	margin-right: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
}

.Container:last-child,
.Container:only-child {
	margin-right: 0px;
}

.Icon {
	padding: 25px;
	background: #d4d4d434;
}

.Text {
	width: 157px;
	text-align: center;
	font-size: 17px;
	color: rgba(48, 48, 48, 0.7);
}
@media only screen and (max-width: 1440px) {

	.Container {
		margin-right: calc(10px + (50 - 10) * ((100vw - 768px) / (1440 - 768)));
	}

	.Container:last-child,
	.Container:only-child {
		margin-right: 0px;
	}

	.Icon {
		padding: calc(15px + (25 - 15) * ((100vw - 768px) / (1440 - 768)));
		width: calc(70px + (100 - 70) * ((100vw - 768px) / (1440 - 768)));
		/* height: calc(90px + (100 - 90) * ((100vw - 768px) / (1440 - 768))); */
	}

	.Text {
		width: calc(80px + (150 - 80) * ((100vw - 768px) / (1440 - 768)));
		font-size: calc(11px + (17 - 11) * ((100vw - 768px) / (1440 - 768)));
	}
}

@media only screen and (max-width: 767px) {
.Container {
		margin-right:calc(20px + (30 - 20) * ((100vw - 375px) / (768 - 375)));
	}

	.Text {
		width: calc(80px + (150 - 80) * ((100vw - 375px) / (768 - 375)));
		font-size: calc(10px + (14 - 10) * ((100vw - 375px) / (768 - 375)));
	}
}