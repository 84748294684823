.Modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
}
.Show {
  display: flex;
}
.Hide {
  display: none;
}
