body {
  margin: 0px;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
}
.GridView {
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
  height: max-content;
}
.heroGridView {
  margin: auto;
  max-width: 1120px;
  height: max-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ValuesGridView {
  margin: auto;
  max-width: 1480px;
  height: max-content;
}
.fullWidth {
  width: 100%;
}
.headerSpot {
  height: 40px;
  width: 100%;
  background-color: #2f4454;
}
.ProfileImgContainer {
  width: 100%;
  overflow: hidden;
  background-color: #fab3b2;
}
.ProfileImgBackG {
  width: 600px;
}
.ProfileImg {
  width: 900px;
}
.GroupImg {
  width: 50%;
  height: auto;
}
.contentHolder {
  width: 100%;
  height: 550px;
}
.missionHolder {
  position: relative;
  width: 100%;
  height: 520px;
  display: grid;
  grid-template-areas:
    "missionTitle missionImage"
    "missionText missionImage";
  grid-template-columns: 340px 500px;
  grid-template-rows: 100px 400px;
  justify-content: center;
  align-content: center;
  align-self: center;
  background-color: white;
  z-index: -1;
}
.title {
  font-family: "Roboto", sans-serif;
  width: 100%;
  padding: 30px 0px 30px 0px;
  font-size: 40px;
  text-align: center;
  color: #444444;
}
.padding {
  height: 500px;
  width: 100%;
  padding: 10px 18% 10px 18%;
}
.PicPad {
  width: 100%;
  padding-left: 18%;
}
.content {
  width: 450px;
  padding: 0px 30px 0px 30px;
  float: left;
  color: #2f4454;
  opacity: 0.8;
  line-height: 1.7;
  font-family: "Roboto", sans-serif;
  text-align: justify;
  word-spacing: -1.5px;
}
.centerContent {
  width: 15%;
  height: 250px;
  margin-top: 15px;
  padding: 3%;
  float: left;
  background-color: #efefef;
  justify-content: center;
}
.valuesContainer {
  background-color: #efefef;
  width: 100vw;
  padding-bottom: 40px;
  margin: auto;
}
.white {
  color: #ffffff;
  height: 10%;
  padding-top: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.heroContent {
  position: relative;
  z-index: 3;
  width: 400px;
}
.topRight {
  width: 400px;
  font-size: 35px;
  font-family: "Roboto", sans-serif;
  opacity: 0.8;
}
.secondColor {
  color: #cc6566;
}
.secondTitle {
  padding: 20px 0px 20px 0px;
  font-size: 40px;
  color: #2f4454;
}
.centerItems {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  hyphens: auto;
}
.centerItemsValues {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.missContainer {
  position: absolute;
  top: -80px;
  left: -120px;
  z-index: 0;
  grid-area: missionText;
  background-color: #efefef;
  float: left;
  width: 350px;
  height: 400px;
  margin-left: 0px;
}
.missImgContainer {
  position: absolute;
  top: 0px;
  left: -220px;
  z-index: -1;
  grid-area: missionImage;
  float: left;
  width: fit-content;
}

.missionTitle {
  position: absolute;
  top: 30px;
  left: -70px;
  z-index: 1;
  grid-area: missionTitle;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  color: #444444;
}
.missionText {
  color: #2f4454;
  font-size: 17px;
  padding: 110px 45px 0px 50px;
  opacity: 0.8;
  line-height: 30px;
}
.missImage {
  width: 860px;
}
.Discrimination {
  margin: auto;
  max-width: 100vw;
  margin-bottom: 50px;
  text-align: justify;
  font-size: 20px;
  color: #2f4454;
  line-height: 1.4;
  font-family: "Roboto";
}
.Discrimination p {
  padding-left: 100px;
  padding-right: 100px;
  color: #444444;
  font-weight: bold;
}
.DiscriminationContent {
  font-size: 13px;
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 1200px) {
  .ProfileImgContainer {
    width: 100vw;
    margin: auto;
  }
  .heroGridView {
    position: relative;
    max-width: 100vw;
    height: calc(300px + (600 - 300) * ((100vw - 600px) / (1200 - 600)));
  }
  .ProfileImgBackG {
    position: absolute;
    width: 60vw;
    bottom: -50px;
    left: -20px;
  }
  .topRight {
    position: absolute;
    top: calc(30px + (55 - 45) * ((100vw - 414px) / (600 - 414)));
    right: 10px;
    font-weight: 500;
    font-size: calc(10px + (20 - 13) * ((100vw - 414px) / (600 - 414)));
    width: calc(175px + (265 - 175) * ((100vw - 414px) / (600 - 414)));
    line-height: calc(18px + (23 - 15) * ((100vw - 414px) / (600 - 414)));
    font-family: "Roboto", sans-serif;
    opacity: 0.8;
  }
  .GridView {
    max-width: 100vw;
  }
  .contentHolder {
    height: auto;
  }
  .content {
    width: 40%;
  }
  .missionHolder {
    position: relative;
    top: 0px;
    left: 0px;
    display: grid;
    grid-template-areas:
      "missionTitle"
      "missionImage"
      "missionText";
    grid-template-columns: 100vw;
    grid-template-rows: 100px 450px 150px;
    width: fit-content;
    text-align: center;
    height: auto;
  }
  .missImgContainer {
    position: relative;
    top: -35px;
    left: -100px;
    grid-area: missionImage;
    width: fit-content;
    margin-left: 0px;
  }
  .missContainer {
    position: relative;
    top: 0px;
    left: 0px;
    grid-area: missionText;
    width: fit-content;
    margin-left: 0px;
    background-color: white;
  }
  .missionTextBg {
    position: relative;
    margin: 0px;
    background-color: white;
    width: fit-content;
    height: auto;
  }
  .missionTitle {
    width: 100vw;
    position: relative;
    top: 0px;
    left: 0px;
    grid-area: missionTitle;
    font-size: calc(26 + (60 - 26) * ((100vw - 600px) / (1200 - 600)));
    background-color: white;
  }
  .missionTitle p {
    padding-top: 0px;
    padding-bottom: 20px;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }
  .missionText {
    font-size: 17px;
    padding: 20px 45px 0px 50px;
    width: fit-content;
    padding: 10px;
  }
  .missImage {
    width: 140vw;
  }
  .DiscriminationContent {
    font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (1200 - 375)));
  }
}
@media (max-width: 600px) {
  .heroGridView {
    height: calc(240px + (380 - 240) * ((100vw - 414px) / (600 - 414)));
  }
  .ProfileImgBackG {
    width: 75vw;
  }
  .GridView {
    margin: auto;
    max-width: 100vw;
    height: max-content;
  }
  .contentHolder {
    position: relative;
    width: fit-content;
    height: 580px;
  }
  .title {
    padding: 0px;
    font-size: 26px;
  }
  .centerItems {
    width: fit-content;
    height: auto;
    display: block;
    padding: 20px;
    padding-top: 0px;
  }
  .content {
    width: fit-content;
    padding: 0px;
    font-size: 16px;
  }
  .missionHolder {
    grid-template-rows: 80px auto 70px;
    height: 480px;
  }
  .missImgContainer {
    position: relative;
    top: -20px;
    left: -80px;
  }
  .missContainer {
    position: relative;
    top: -120px;
    background-color: white;
  }
  .missionTextBg {
    background-color: #efefef;
  }
  .missionTitle {
    font-size: 26px;
  }
  .valuesContainer {
    background-color: white;
  }
  .ValuesGridView {
    max-width: 100vw;
  }
  .secondTitle {
    font-size: 26px;
  }
  .centerItemsValues {
    width: 100%;
    display: block;
  }
  .Discrimination {
    width: 100vw;
    text-align: center;
    hyphens: auto;
    font-size: 18px;
    padding-top: 20px;
    margin-bottom: 0px;
    background-color: #efefef;
  }
  .Discrimination p {
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .DiscriminationContent {
    margin: 14px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
    margin-bottom: 0px;
  }
}
