.courseList *{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.courseList {
	grid-column: 2;
	grid-row: 2;
    justify-self: start;
	width: 300px;
	height: max-content;
	margin: 100px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.button {
	color: #ffffff;
	width: 100%;
	height: 80px;
	background-color: #2f4454;
	border: none;
	outline: none;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #fbfcfd;
}

.button:hover {
	background-color: #ececed;
	color: #444444;
	transition: color .4s ease, background-color .4s ease;
}
.active{
	background-color: #ececed;
	color: #444444;
}

.title {
	font-size: 17px;
	font-weight: bold;
}

.type {
	font-size: 11px;
	margin-top: 5px;
}
.open_close{
	display: none;
}

/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1316px) {
	.courseList {
		width: calc(200px + (300 - 200) * ((100vw - 897px) / (1316 - 897)));
	}
	.button {
		height: calc(60px + (80 - 60) * ((100vw - 897px) / (1316 - 897)));
	}
	.title {
		font-size: calc(12px + (17 - 12) * ((100vw - 897px) / (1316 - 897)));
	}
	.type {
		font-size: calc(10px + (11 - 10) * ((100vw - 897px) / (1316 - 897)));
	}
}
@media only screen and (max-width: 896px) {
	.courseList {
		width: 200px;
	}
	.button {
		height: 60px;
	}
	.title {
		font-size: 12px;
	}

	.type {
		font-size: 10px;
	}
}
@media only screen and (max-width: 600px) {
	.courseList {
    	grid-column: 1;
		grid-row: 2;
		width: 100vw;
		margin: 0px;
		padding: 0px;
		margin-top: calc(71px + (81 - 71) * ((100vw - 897px) / (1316 - 897)));
		z-index: 1;
	}
	.button {
		height: 80px;
		transition: all 300ms ease;
	}
	.hide{
		opacity: 0;
		height: 0px;
		border-bottom: 0px solid #fbfcfd;
		visibility: hidden;
		
	}
	.open_close{
		display:block;
		border-radius: 100%;
		border: none;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		width: max-content;
		height: max-content;
		margin-top: -2px;
	}
	.open{
		width: 101px;
		height: 78px;
	}
	.close{
		width: 68px;
		height: 71px;
	}
}
