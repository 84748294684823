.Footer {
  font-family: "Roboto", sans-serif;
  width: 100% !important;
  background-color: #21303c;
  width: 100vw;
  height: 350px;
}
.Grid {
  margin: auto auto;
  max-width: 1080px;
}
.TipOfDayContent {
  position: relative;
  margin: auto;
  margin-top: 30px;
  display: grid;
  grid-template-areas:
    "logo title"
    "logo title2"
    "inputForm inputForm";
  grid-template-rows: 30px 40px 40px;
  grid-template-columns: 120px 340px;
  justify-content: center;
  align-self: center;
  align-items: center;
  letter-spacing: 0.05em;
  width: 400px;
}
.Logo {
  grid-area: logo;
  width: 80%;
}

.title {
  position: absolute;
  top: 0px;
  left: 32px;
  grid-area: title;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  margin: auto;
  color: #ffffff;
}
.title2 {
  grid-area: title2;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 128.91%;
  letter-spacing: 0.05em;
  margin: auto;
  margin-top: none;
  margin-bottom: none;
  color: #ffffff;
}
.inputForm {
  grid-area: inputForm;
  margin-top: 21px;
  position: relative;
  width: 480px;
}
.inputf {
  position: relative;
  top: 0px;
  left: -20px;
  width: 75%;
  height: 35px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #21303c;
  color: #ffffff;
}
.inputButton {
  position: relative;
  top: 0px;
  right: 0px;
  width: 20%;
  height: 35px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #21303c;
  color: #ffffff;
}

.moreInformation {
  display: grid;
  grid-template-areas: "support explore connect contact";
  grid-template-columns: 100px 100px 100px auto;
  grid-column-gap: 50px;
  align-content: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 120px;
  color: #ffffff;
}
.moreInfoTitles {
  margin-top: 20px;
  height: 20px;
  font-weight: bold;
  font-size: 18px;
}
.contact {
  cursor: pointer;
}
.supportContainer {
  grid-area: support;
  text-align: center;
}
.exploreContainer {
  grid-area: explore;
  text-align: center;
  display: block;
}
.connectContainer {
  grid-area: connect;
  text-align: center;
}
.contactContainer {
  grid-area: contact;
}
.moreInformation a {
  color: #ffffff;
  text-decoration: none;
  display: inline-table;
  margin-top: 12px;
}
.footerImg {
  width: 19px;
  margin-right: 10px;
}
@media (max-width: 900px) {
  .moreInformation {
    grid-template-columns: 80px 70px 80px auto;
    grid-column-gap: 30px;
    margin-left: 20px;
  }
  .moreInformation .moreInfoTitles {
    font-size: 15px;
  }
  .moreInformation a {
    font-size: 13px;
  }
  .moreInformation p {
    font-size: 13px;
  }
  .footerImg {
    width: 15px;
    margin-right: 5px;
  }
}
@media (max-width: 600px) {
  .TipOfDayContent {
    position: relative;
    margin-top: 20px;
    grid-template-columns: 100px 280px;
    grid-template-rows: 40px 40px 40px;
  }
  .Logo {
    position: relative;
    top: -15px;
    left: 0px;
    grid-area: logo;
    width: 100%;
  }
  .title {
    position: relative;
    top: 18px;
    left: 20px;
    font-size: 20px;
    line-height: 27px;
    margin: initial;
  }
  .title2 {
    position: relative;
    top: 0px;
    left: -25px;
    margin: initial;
    font-size: 12px;
    line-height: 128.91%;
    padding-top: 5px;
  }
  .inputForm {
    margin-top: 0px;
    width: 100%;
  }
  .inputf {
    left: 30px;
    width: 60%;
  }
  .inputButton {
    left: 40px;
    width: 22%;
  }
  .moreInformation {
    grid-template-areas:
      "connect connect"
      "support contact";
    grid-template-columns: 90px 250px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .connectContainer {
    grid-area: connect;
    text-align: center;
    position: relative;
    left: 0px;
    font-size: 15px;
  }
  .connectContainer .moreInfoTitles {
    display: none;
  }
  .connectContainer a {
    margin-right: 30px;
  }
  .moreInfoTitles {
    font-size: 14px;
  }
  .supportContainer {
    grid-area: support;
    text-align: center;
    position: relative;
    left: 25px;
  }
  .supportContainer p {
    font-size: 14px;
  }
  .contactContainer {
    grid-area: contact;
    position: relative;
    left: 10px;
  }
  .contactContainer .moreInfoTitles {
    font-size: 14px;
    text-align: center;
  }
  .contactContainer p {
    font-size: 12px;
    margin-left: 15px;
  }
  .exploreContainer {
    display: none;
  }
  .moreInformation a {
    font-size: 14px;
  }
  .footerImg {
    width: 15px;
    margin-right: 10px;
  }
}
