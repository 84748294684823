.GuaranteeVideo {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 600px;
	background: transparent;
	overflow: hidden;
	margin: auto;
}

.Video {
	width: 0px;
}

.GuaranteeVideoInnerShadow {
	height: 100%;
	width: 100%;
	background-image: url('../images/office.jpeg');
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
}

.GuaranteeVideoInnerShadow::before {
	content: "";
	background: #2f445499;
	position: absolute;
	height: 100%;
	width: 100%;
	box-shadow: inset 0 0 10px #000000;
}

.PlayButton {
	width: 145px;
	height: 145px;
	background: transparent;
	border-radius: 50%;
	position: absolute;
	outline: none;
	border: none;
	filter: drop-shadow(0 0 0.5rem #000000ad);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

/* pulse wave */
.PlayButton:before {
	content: "";
	position: absolute;
	width: 150%;
	height: 150%;
	animation-delay: 0s;
	animation: pulsate1 2s;
	animation-direction: forwards;
	animation-iteration-count: infinite;
	animation-timing-function: steps;
	opacity: 1;
	border-radius: 50%;
	border: 5px solid rgba(255, 255, 255, .75);
	background: rgba(198, 16, 0, 0);
}

@keyframes pulsate1 {
	0% {
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 1;
		box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
	}
	100% {
		-webkit-transform: scale(1, 1);
		transform: scale(1);
		opacity: 0;
		box-shadow: none;
	}
}

@media only screen and (max-width: 1440px) {
	.GuaranteeVideo {
		height: calc(400px + (600 - 400) * ((100vw - 375px) / (1440 - 375)));
	}
	.GuaranteeVideoInnerShadow{
		background-image: url('../images/officeM.jpg');
		background-attachment: scroll;
	}
	.PlayButton {
		width: calc(100px + (145 - 100) * ((100vw - 375px) / (1440 - 375)));
		height: calc(100px + (145 - 100) * ((100vw - 375px) / (1440 - 375)));
	}
}