html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.App {
  height: 100vh;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  justify-items: stretch;
}

* {
  font-family: "Roboto", sans-serif;
}

button {
	cursor: pointer;
  user-select: none; /* Non-prefixed version, currently */
}

img{
  -webkit-user-drag: none;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
}
