.Container * {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto";
  font-style: normal;
  box-sizing: border-box;
}
.Container {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  position: relative;
  display: grid;
  grid-template-columns: auto auto 177px;
  grid-column-gap: 10px;
  padding: 24px 52px 34px 24px;
  box-sizing: border-box;
}
.QuotationMarks {
  width: 30px;
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: max-content;
  height: max-content;
  padding-top: 20px;
}
.img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  object-fit: cover;
}

.position {
  width: max-content;
  margin-top: 5px;
  font-size: 16px;
}
.name {
  font-size: 13px;
  margin-top: 4px;
  width: max-content;
}

.Quote {
  text-align: center;
  font-size: 16px;
  padding-right: 40px;
  padding-top: 23px;
  text-align: justify;
  hyphens: auto;
}

@media only screen and (max-width: 1024px) {
  .TextTestimonialCarousel {
    width: calc(600px + (1000 - 600) * ((100vw - 560px) / (1024 - 560)));
    height: calc(240px + (250 - 240) * ((100vw - 560px) / (1024 - 560)));
  }
  .img {
    width: calc(100px + (120 - 100) * ((100vw - 560px) / (1024 - 560)));
    height: calc(100px + (120 - 100) * ((100vw - 560px) / (1024 - 560)));
  }
  .position {
    font-size: calc(15px + (16 - 15) * ((100vw - 560px) / (1024 - 560)));
  }
  .name {
    margin-top: calc(3px + (4 - 3) * ((100vw - 560px) / (1024 - 560)));
  }
  .Quote {
    font-size: calc(15px + (16 - 15) * ((100vw - 375px) / (1024 - 375)));
    padding-right: calc(10px + (40 - 10) * ((100vw - 560px) / (1024 - 560)));
    padding-top: calc(10px + (20 - 10) * ((100vw - 560px) / (1024 - 560)));
  }
}

@media only screen and (max-width: 767px) {
  .Container {
    width: 100%;
    height: 100%;
    justify-items: center;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-row-gap: 20px;
    padding: 0px 14px 24px 14px;
  }
  .info {
    grid-column: 1/3;
    grid-row: 1;
    padding-top: 0px;
    margin-top: -50px;
  }

  .img {
    width: 100px;
    height: 100px;
  }

  .name {
    font-size: 13px;
    margin-top: 4px;
  }

  .QuotationMarks {
    grid-row: 2;
    margin-top: -23px;
    grid-column: 1/3;
    justify-self: flex-start;
  }

  .Quote {
    grid-row: 2;
    padding: 0px 20px;
    padding-bottom: 20px;
    padding-top: 0px;
    grid-column: 2/3;
  }
}
