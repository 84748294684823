.videoTestimonial * {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}
.videoTestimonial {
	width: 330px;
	text-align: center;
	background: url(../images/botbackground.png), #f2f2f2;
	background-size: cover;
	box-shadow: 0px 2.14819px 34.3711px rgba(0, 0, 0, 0.6);
	opacity: 0;
}
.info{
	width: 100%;
	height: 104px;
	display: grid;
    justify-items: center;
    align-items: start;
}
.profilePic {
	width: 75px;
	height: 75px;
	border-radius: 100%;
	border: 4.67809px solid #ffffff;
	object-fit: cover;
    top: -50%;
    margin-bottom: -20px;
    position: relative;
}

.name {
	font-family: "Roboto", sans-serif;
	font-size: 13px;
	font-weight: bold;
	margin-bottom: 5px;
}

.position {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	font-weight: bold;
}

.triangle{
    border-color: #f2f2f2 transparent transparent transparent;
    border-style: solid;
    border-width: 20px 15px 0 15px;
	margin-top: 8px;
	z-index: 1;
}

/* calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1440px) {
	.videoTestimonial {
		width: calc(188px + (330 - 188) * ((100vw - 897px) / (1440 - 897)));
	}
	.info{
		height: calc(80px + (104 - 80) * ((100vw - 897px) / (1440 - 897)));
	}
	.profilePic {
		width: calc(55px + (75 - 55) * ((100vw - 897px) / (1440 - 897)));
		height: calc(55px + (75 - 55) * ((100vw - 897px) / (1440 - 897)));
		margin-bottom: calc(-15px + (-20 - (-15)) * ((100vw - 897px) / (1440 - 897)));
	}

	.name {
		font-size: calc(10px + (13 - 10) * ((100vw - 897px) / (1440 - 897)));
		margin-bottom: calc(3px + (5 - 3) * ((100vw - 897px) / (1440 - 897)));
	}

	.position {
		font-size: calc(10px + (16 - 10) * ((100vw - 897px) / (1440 - 897)));
	}
	
	.triangle{
		margin-top: calc(5px + (8 - 5) * ((100vw - 897px) / (1440 - 897)));
	}
}

@media only screen and (max-width: 896px) {
	.videoTestimonial {
		width: calc(188px + (430 - 188) * ((100vw - 375px) / (896 - 375)));
	}	
	.info{
		height: calc(75px + (104 - 75) * ((100vw - 375px) / (896 - 375)));
	}
	.profilePic {
		width: calc(55px + (75 - 55) * ((100vw - 375px) / (896 - 375)));
		height: calc(55px + (75 - 55) * ((100vw - 375px) / (896 - 375)));
		margin-bottom: calc(-15px + (-20 - (-15)) * ((100vw - 375px) / (896 - 375)));
	}

	.name {
		font-size: calc(10px + (13 - 10) * ((100vw - 375px) / (896 - 375)));
		margin-bottom: calc(3px + (5 - 3) * ((100vw - 375px) / (896 - 375)));
	}

	.position {
		font-size: calc(10px + (16 - 10) * ((100vw - 375px) / (896 - 375)));
	}
	
	.triangle{
		margin-top: calc(5px + (8 - 5) * ((100vw - 375px) / (896 - 375)));
	}
}