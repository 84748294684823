.coursePage *{
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.coursePage{
    display: grid;
    justify-items: center;
    align-items: start;
    grid-column-gap: 1px;
}

.chairImg{
  width: 100%;
  grid-column: 1/3;
  grid-row: 1;
}
@media only screen and (max-width: 600px) {
  .chairImg{
    grid-column: 1;
    grid-row: 1;
    height: 85px;
  }
}