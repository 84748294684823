.Carousel {
  width: 100vw;
  height: 500px;
  background-color: #f5f5f500;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  margin: auto;
}
.Slides {
  width: 100%;
  height: 100%;
  background-color: #f5f5f500;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
}
.SlideWrapper {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.arrowButtonContainer {
  max-width: 1680px;
  width: 100vw;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.arrowButton {
  width: 83px;
  height: 83px;
  padding: 0px 0px 0px 9px;
  background: rgba(158, 158, 158, 0.4);
  border: none;
  border-radius: 50%;
  margin: 0px 30px;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}
.arrowButtonHide {
  display: none;
}

.arrowButton:active {
  background: #ffffff79;
}

.arrowButton:first-child {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.arrowButtonIconContainer {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-filter: drop-shadow(0px 3.70062px 13.2165px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 3.70062px 13.2165px rgba(0, 0, 0, 0.5));
}

.EEER {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 90px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  bottom: -45px;
  background: #f5f5f5;
  box-shadow: 0px 3.70062px 13.2165px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 0px 40px;
}
.EEER p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  background: -webkit-linear-gradient(
    45deg,
    #da5858,
    #5491ed,
    #dac760,
    #4aa759 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.DotButtonContainer {
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  bottom: 0;
}
.DotButton {
  width: 14px;
  height: 14px;
  margin: 0px 10px 10px 0px;
  border-radius: 50%;
  border: none;
  background: #cecece;
  outline: none;
}
.DotButton:last-child {
  margin-right: 0px;
}
.DotButtonActive {
  background: #969696;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 1440px) {
  .Carousel {
    height: calc(150px + (500 - 150) * ((100vw - 375px) / (1440 - 375)));
  }

  .EEER {
    height: calc(36px + (90 - 36) * ((100vw - 375px) / (1440 - 375)));
    bottom: calc(-18px + (-45 + 18) * ((100vw - 375px) / (1440 - 375)));
    padding: 0px calc(15px + (40 - 15) * ((100vw - 375px) / (1440 - 375)));
  }

  .EEER p {
    font-size: calc(12px + (30 - 12) * ((100vw - 375px) / (1440 - 375)));
  }
  .arrowButton {
    width: calc(20px + (83 - 20) * ((100vw - 375px) / (1440 - 375)));
    height: calc(20px + (83 - 20) * ((100vw - 375px) / (1440 - 375)));
    padding: 0px 0px 0px calc(2px + (9 - 2) * ((100vw - 375px) / (1440 - 375)));
    margin: 0px calc(20px + (30 - 20) * ((100vw - 375px) / (1440 - 375)));
  }
  .arrowButtonIcon {
    width: calc(15px + (55 - 15) * ((100vw - 375px) / (1440 - 375)));
    height: calc(15px + (55 - 15) * ((100vw - 375px) / (1440 - 375)));
  }
}
