.RegisterForm {
  background-color: #ffffff;
  width: 1011.73px;
  height: max-content;
  font-family: "Roboto", sans-serif;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.RegisterForm .CloseButton {
  float: right;
  margin: 6px;
  background-color: transparent;
  border: none;
  padding: 0px;
  display: flex;
  cursor: pointer;
}

.RegisterForm .SubTitle {
  width: 600px;
  height: 47.93px;
  font-family: inherit;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  text-align: center;
  color: rgba(47, 68, 84, 0.8);
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
}
.RegisterForm form {
  text-align: center;
  margin: auto;
  margin-top: 20px;
}
.RegisterForm form .Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 778.05px;
  margin: auto;
}
.RegisterForm form .Container:nth-child(2) {
  justify-content: start;
}
.RegisterForm form .Container:nth-child(3) {
  justify-content: center;
}
.RegisterForm .Container .Input {
  width: 277.05px;
  height: 70px;
  margin-bottom: 20px;
}
.RegisterForm .Container .Textarea {
  width: 500px;
  height: 85px;
  margin-bottom: 70px;
  margin-top: 10px;
  outline: none;
}
.RegisterForm .FromSubmit {
  width: 200px;
  height: 40.36px;
  background-color: rgb(47, 68, 84);
  font-weight: 600;
  font-size: 18.1337px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  margin-bottom: 30px;
  border: none;
}
.RegisterForm .MessageContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
}
.RegisterForm .MessageContainer .message {
  font-weight: 100;
  font-size: 14.1337px;
  color: rgba(47, 68, 84, 0.7);
  text-align: right;
}
@media (max-width: 600px) {
  .RegisterForm {
    width: 100vw;
    padding-bottom: 20px;
  }
  .RegisterForm .SubTitle {
    font-size: 15px;
    margin-top: 30px;
    width: fit-content;
    height: 50.93px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .RegisterForm form {
    justify-content: center;
    align-items: center;
    height: 340px;
  }
  .RegisterForm form .Container {
    display: grid;
    grid-template-columns: "name" "email" "phone";
    grid-column-gap: 20px;
    justify-items: center;
    align-items: center;
    width: 100%;
    margin: 0px;
  }
  .RegisterForm form .Container:nth-child(2) {
    justify-content: start;
    display: flex;
    margin: auto;
  }
  .RegisterForm form .Container:nth-child(3) {
    justify-content: center;
    display: flex;
    margin: auto;
  }
  .RegisterForm .Container .Input {
    width: 80%;
    height: 55px;
    margin-bottom: 0px;
  }
  .RegisterForm .Container .Textarea {
    width: 80vw;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 15px;
  }
  .RegisterForm .MessageContainer {
    padding: 0px 0px 0px 0px;
    width: 100%;
    display: block;
  }
  .RegisterForm .MessageContainer .message {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 10px;
  }
}
