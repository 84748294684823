.Values {
	font-size: 15px;
	padding: 0% 1% 0% 1%;
	height: 100%;
	width: 18%;
	text-align: center;
}
.ValueImg {
	width: 55%;
	height: auto;
}
.white {
	color: #2F4454;
	height: 10%;
	padding-top: 5%;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	font-size: 25px;
	font-weight: bolder;
}
.LineDivider {
	width: 99%;
	height: 9px;
	border-style: solid;
	border-width: 2px 0px 0px 0px;
	border-color: #2F4454;
}
.valueDescription {
	text-align: center;
	color: #2F4454;
}

@media (max-width:600px){

	.Values:nth-of-type(even){
		grid-template-areas: "valuesText valuesImage" 
							"valuesText valuesTitle";	
		grid-template-columns: 200px 160px;
	}
	.Values {
		width: fit-content;
		text-align: center;
		display: grid;
		grid-template-areas: "valuesImage valuesText" 
							"valuesTitle valuesText";
		grid-template-columns: 160px 200px;
		grid-column-gap: 11px;
		margin: auto;
		margin-bottom: 41px;
		justify-content: center;
		align-content: center;
		text-align: center;	
	}
	.white {
		grid-area: valuesTitle;
		font-weight: bold;
		line-height: 28px;
	}

	.ValueImg {
		grid-area: valuesImage;
		width: 100%;
		height: 150px;
	}
	.LineDivider {
		display: none;
	}
	.valueDescription {
		grid-area: valuesText;
		margin: auto;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.05em;
	}

}